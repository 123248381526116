import React, { Fragment } from 'react';
import {
    faUserCircle,
    faMicrophone,
    faMicrophoneSlash,
    faVideo,
    faVideoSlash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import useIsTrackEnabled from '../../hooks/useIsTrackEnabled';
import usePublications from '../../hooks/usePublications';
import useTrack from '../../hooks/useTrack';

const NameText = styled.p`
    margin-left: 10px;
    line-height: 30px;
    font-weight: bold;
`;

const IconContainer = styled.div`
    margin-left: auto;
    line-height: 30px;
`;

const Icon = styled.div`
    display: inline-block;
    width: 20px;
    text-align: center;
`;

const ParticipantSidebarInfo = ({ participant }) => {
    const publications = usePublications(participant);
    const audioPublication = publications.find(p => p.kind === 'audio');
    const videoPublication = publications.find(p =>
        p.trackName.includes('camera')
    );
    const audioTrack = useTrack(audioPublication);
    const isAudioEnabled = useIsTrackEnabled(audioTrack);
    const isVideoEnabled = typeof videoPublication !== 'undefined';

    return (
        <Fragment>
            <FontAwesomeIcon icon={faUserCircle} size="2x" />
            <NameText>{participant.identity}</NameText>
            <IconContainer>
                <Icon>
                    {isAudioEnabled ? (
                        <FontAwesomeIcon icon={faMicrophone} size="1x" />
                    ) : (
                        <FontAwesomeIcon icon={faMicrophoneSlash} size="1x" />
                    )}
                </Icon>
                <Icon className="ml-2">
                    {isVideoEnabled ? (
                        <FontAwesomeIcon icon={faVideo} size="1x" />
                    ) : (
                        <FontAwesomeIcon icon={faVideoSlash} size="1x" />
                    )}
                </Icon>
            </IconContainer>
        </Fragment>
    );
};

export default ParticipantSidebarInfo;
