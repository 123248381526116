import React from 'react';
import styled from 'styled-components';
import MainParticipant from './MainParticipant';
import Participant from './Participant';

const ScreenSharingContainer = styled.div`
    width: 95%;
    height: 75%;
    margin: 0 auto;
    position: relative;
`;

const LocalParticipantContainer = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 225px;
    height: 126px;
    display: table;
    border: 1px solid #ffffff;
`;

const ScreenShareContainer = ({ participant }) => {
    return (
        <ScreenSharingContainer>
            <MainParticipant
                disableAudio={true}
                isScreenShareParticipant={true}
            />
            {participant && (
                <LocalParticipantContainer>
                    <Participant
                        participant={participant}
                        noSizeLimit
                        localIndividual={true}
                        displayCameraOverScreen
                        disableAudio={true}
                    />
                </LocalParticipantContainer>
            )}
        </ScreenSharingContainer>
    );
};

export default ScreenShareContainer;
