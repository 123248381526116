import React, { createContext, useState, useEffect } from 'react';
import _debounce from 'lodash.debounce';

export const ResponsiveContext = createContext(null);

const ResponsiveContextProvider = ({ children }) => {
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        const debouncedHandleResize = _debounce(handleResize, 250);
        window.addEventListener('resize', debouncedHandleResize);
        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, []);
    return (
        <ResponsiveContext.Provider value={dimensions}>
            {children}
        </ResponsiveContext.Provider>
    );
};

export default ResponsiveContextProvider;
