import { useEffect, useState } from 'react';

const usePublications = participant => {
    const [publications, setPublications] = useState([]);

    useEffect(() => {
        setPublications(Array.from(participant.tracks.values()));

        const publicationAdded = publication => {
            setPublications(prev => [...prev, publication]);
        };
        const publicationRemoved = publication => {
            setPublications(prev => prev.filter(p => p !== publication));
        };

        participant.on('trackPublished', publicationAdded);
        participant.on('trackUnpublished', publicationRemoved);

        return () => {
            participant.off('trackPublished', publicationAdded);
            participant.off('trackUnpublished', publicationRemoved);
        };
    }, [participant]);

    return publications;
};

export default usePublications;
