import React from 'react';

const FullscreenOffIcon = props => {
    return (
        <svg
            className={props.className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M23.3844 0.614995C23.1498 0.380748 22.8319 0.249176 22.5004 0.249176C22.1689 0.249176 21.8509 0.380748 21.6164 0.614995L18.2004 4.027C18.1533 4.07377 18.0897 4.10003 18.0234 4.10003C17.957 4.10003 17.8934 4.07377 17.8464 4.027L16.5254 2.7C16.3855 2.56019 16.2073 2.46498 16.0134 2.42641C15.8194 2.38784 15.6184 2.40765 15.4357 2.48332C15.253 2.55899 15.0968 2.68713 14.987 2.85154C14.8771 3.01595 14.8184 3.20924 14.8184 3.407V8.18099C14.8184 8.44621 14.9237 8.70057 15.1113 8.8881C15.2988 9.07564 15.5531 9.18099 15.8184 9.18099H20.5914C20.7893 9.18135 20.9829 9.12296 21.1476 9.01323C21.3123 8.9035 21.4407 8.74736 21.5167 8.56458C21.5926 8.38179 21.6125 8.18059 21.574 7.98645C21.5355 7.79232 21.4403 7.61397 21.3004 7.474L19.9724 6.14799C19.9256 6.10113 19.8994 6.03766 19.8994 5.9715C19.8994 5.90533 19.9256 5.84186 19.9724 5.795L23.3844 2.383C23.6186 2.14844 23.7502 1.83049 23.7502 1.499C23.7502 1.1675 23.6186 0.849555 23.3844 0.614995Z" fill="#E70707"/>
            <path d="M0.615819 23.383C0.850379 23.6172 1.16832 23.7488 1.49982 23.7488C1.83132 23.7488 2.14926 23.6172 2.38382 23.383L5.79982 19.971C5.84685 19.9242 5.91049 19.898 5.97682 19.898C6.04315 19.898 6.10679 19.9242 6.15382 19.971L7.47482 21.3C7.61467 21.4398 7.79283 21.535 7.98679 21.5736C8.18074 21.6121 8.38177 21.5923 8.56447 21.5167C8.74717 21.441 8.90334 21.3129 9.01322 21.1484C9.12311 20.984 9.18178 20.7907 9.18182 20.593V15.817C9.18182 15.5518 9.07646 15.2974 8.88893 15.1099C8.70139 14.9223 8.44704 14.817 8.18182 14.817H3.40882C3.2109 14.8166 3.01732 14.875 2.85261 14.9848C2.68789 15.0945 2.55944 15.2506 2.48352 15.4334C2.4076 15.6162 2.38763 15.8174 2.42614 16.0115C2.46464 16.2057 2.55989 16.384 2.69982 16.524L4.02782 17.85C4.07453 17.8968 4.10077 17.9603 4.10077 18.0265C4.10077 18.0927 4.07453 18.1561 4.02782 18.203L0.615819 21.615C0.381572 21.8495 0.25 22.1675 0.25 22.499C0.25 22.8305 0.381572 23.1484 0.615819 23.383Z" fill="#E70707"/>
            <path d="M14.8184 20.59C14.8184 20.7877 14.8771 20.981 14.987 21.1454C15.0968 21.3099 15.253 21.438 15.4357 21.5137C15.6184 21.5893 15.8194 21.6091 16.0134 21.5706C16.2073 21.532 16.3855 21.4368 16.5254 21.297L17.8514 19.971C17.8984 19.9242 17.962 19.898 18.0284 19.898C18.0947 19.898 18.1583 19.9242 18.2054 19.971L21.6164 23.383C21.8508 23.6174 22.1688 23.7492 22.5004 23.7492C22.8319 23.7492 23.1499 23.6174 23.3844 23.383C23.6188 23.1485 23.7505 22.8306 23.7505 22.499C23.7505 22.1674 23.6188 21.8494 23.3844 21.615L19.9724 18.2C19.9256 18.1531 19.8994 18.0897 19.8994 18.0235C19.8994 17.9573 19.9256 17.8938 19.9724 17.847L21.3004 16.524C21.4402 16.3841 21.5354 16.206 21.5739 16.012C21.6125 15.8181 21.5927 15.617 21.517 15.4343C21.4414 15.2516 21.3132 15.0955 21.1488 14.9856C20.9844 14.8757 20.7911 14.817 20.5934 14.817H15.8184C15.5531 14.817 15.2988 14.9223 15.1113 15.1099C14.9237 15.2974 14.8184 15.5518 14.8184 15.817V20.59Z" fill="#E70707"/>
            <path d="M9.18217 3.40801C9.18213 3.21013 9.12339 3.0167 9.01338 2.85222C8.90337 2.68774 8.74704 2.5596 8.56417 2.484C8.38185 2.40704 8.18065 2.38649 7.98654 2.42499C7.79242 2.46349 7.6143 2.55928 7.47516 2.70001L6.14917 4.02701C6.10213 4.07378 6.0385 4.10004 5.97217 4.10004C5.90583 4.10004 5.8422 4.07378 5.79517 4.02701L2.38416 0.615005C2.14971 0.380554 1.83173 0.24884 1.50016 0.24884C1.1686 0.24884 0.850616 0.380554 0.616165 0.615005C0.381713 0.849457 0.25 1.16744 0.25 1.49901C0.25 1.83057 0.381713 2.14855 0.616165 2.38301L4.02816 5.80001C4.07488 5.84687 4.10111 5.91034 4.10111 5.97651C4.10111 6.04267 4.07488 6.10614 4.02816 6.15301L2.70016 7.47401C2.56036 7.61386 2.46515 7.79202 2.42658 7.98597C2.38801 8.17993 2.40782 8.38096 2.48349 8.56366C2.55916 8.74636 2.6873 8.90252 2.85171 9.01241C3.01612 9.12229 3.20941 9.18096 3.40716 9.181H8.18217C8.44738 9.181 8.70174 9.07565 8.88927 8.88811C9.07681 8.70057 9.18217 8.44622 9.18217 8.18101V3.40801Z" fill="#E70707"/>
        </svg>
    );
};

export default FullscreenOffIcon;
