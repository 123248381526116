import React, { useContext, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import { VideoContext } from '../../context/VideoContext';
import AudioLevel from './AudioLevel';
import VideoTrack from './VideoTrack';

const LocalPreview = () => {
    const { initializeTracks, localTracks, preEnabled, audioMode, setVideoTrackEnabled,setAudioMode } = useContext(VideoContext);

    useEffect(() => {
        initializeTracks();
    });

    const videoTrack = localTracks.find(track => track.name === 'camera');
    const audioTrack = localTracks.find(track => track.kind === 'audio');

    if(videoTrack!=undefined){
       if(audioMode){
            setAudioMode(false);
            setVideoTrackEnabled(false);
       }
    }

    return (
        <Card className="remove-border">
            <CardBody className="p-2">
                {videoTrack &&
                    <VideoTrack track={videoTrack} noSizeLimit isPreview />
                }
                <br/>
                {audioTrack &&
                    <AudioLevel audioTrack={audioTrack} isMuted={!preEnabled.audio} />
                }
            </CardBody>
        </Card>
    );
};

export default LocalPreview;
