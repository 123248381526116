import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Progress } from 'reactstrap';

const IconContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  '& div': {
    width: '3px',
    boxSizing: 'content-box',
    '&:not(:last-child)': {
      marginRight: '2px',
    },
  },
});

const STEP = 3;

const NetworkQuality = ({ networkQualityLevel, variant }) => {
    if (networkQualityLevel === null) return null;
    return (variant === 'icon' ?
      <IconContainer>
        {[0, 1, 2, 3, 4].map(level => (
          <div
            key={level}
            style={{
              height: `${STEP * (level + 1) + 3}px`,
              background: networkQualityLevel > level ? '#EBD720' : '#FFF',
            }}
          />
        ))}
      </IconContainer>
        :
      <Fragment>
          <h6 className="mb-1">Network Quality Level</h6>
          {networkQualityLevel === 1 && (
              <Progress value={20} color="danger" />
          )}
          {networkQualityLevel === 2 && (
              <Progress value={40} color="warning" />
          )}
          {networkQualityLevel === 3 && (
              <Progress value={60} color="primary" />
          )}
          {networkQualityLevel === 4 && <Progress value={80} color="info" />}
          {networkQualityLevel === 5 && (
              <Progress value={100} color="success" />
          )}
      </Fragment>
    );
};

export default NetworkQuality;
