import React, { createContext, useState } from 'react';
import axios from 'axios';
import useSelectedParticipant from '../hooks/useSelectedParticipant';
import useLocalTracks from '../hooks/useLocalTracks';
import useRoom from '../hooks/useRoom';

export const VideoContext = createContext(null);

const VideoContextProvider = ({ options, children }) => {
    const [activeSinkId, setActiveSinkId] = useState('default');
    const [audioMode, setAudioMode]= useState(false);
    
    const [preEnabled, setPreEnabled] = useState({
        video: true,
        audio: true
    });
    const {
        localTracks,
        initializeTracks,
        releaseTracks,
        getLocalVideoTrack,
        getLocalAudioTrack,
        errorMessage
    } = useLocalTracks();
    const { room, connect, connectionErrorMessage } = useRoom(
        localTracks,
        options,
        preEnabled
    );
    const {
        selectedParticipant,
        toggleSelectedParticipant
    } = useSelectedParticipant(room);

    const setVideoTrackEnabled = async shouldEnable => {
        const videoTrack = localTracks.find(
            track => track.name === 'camera' && track.isStopped === false
        );

        if (!shouldEnable && videoTrack) {
            videoTrack.stop();
        } else if (shouldEnable && !videoTrack) {
            getLocalVideoTrack();
        }
    };

    const backOff = wait => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, wait);
        });
    };

    const getToken = async (
        identity,
        room,
        wait = 500,
        attempt = 1,
        maxTries = 5
    ) => {
        try {
            if (attempt > 1) {
                await backOff(wait);
            }
            const { data } = await axios.get(
                `${process.env.REACT_APP_FUNCTION_ENDPOINT}/getVideoToken?identity=${identity}&room=${room}`
            );
            return data;
        } catch (error) {
            if (
                error.response &&
                (error.response.status === 429 ||
                    error.response.data.code === 54009)
            ) {
                if (attempt < maxTries) {
                    let cap = 5000;
                    let base = 500;
                    wait = Math.min(cap, base * 2 * attempt);
                    return getToken(identity, room, wait, ++attempt, maxTries);
                } else {
                    console.log(
                        'Error getting video token after max tries',
                        error
                    );
                    return {
                        token: null
                    };
                }
            }
        }
    };

    const validatePin = async (
        room,
        pin,
        wait = 500,
        attempt = 1,
        maxTries = 5
    ) => {
        try {
            if (attempt > 1) {
                await backOff(wait);
            }
            const { data } = await axios.get(
                `${process.env.REACT_APP_FUNCTION_ENDPOINT}/roomValidator?room=${room}&pin=${pin}`
            );
            return data;
        } catch (error) {
            if (
                error.response &&
                (error.response.status === 429 ||
                    error.response.data.code === 54009)
            ) {
                if (attempt < maxTries) {
                    let cap = 5000;
                    let base = 500;
                    wait = Math.min(cap, base * 2 * attempt);
                    return validatePin(room, pin, wait, ++attempt, maxTries);
                } else {
                    console.log(
                        'Error validating PIN after three tries',
                        error
                    );
                    return {
                        roomValidation: false
                    };
                }
            }
        }
    };

    return (
        <VideoContext.Provider
            value={{
                getToken,
                validatePin,
                setVideoTrackEnabled,
                connect,
                room,
                initializeTracks,
                releaseTracks,
                localTracks,
                getLocalVideoTrack,
                getLocalAudioTrack,
                selectedParticipant,
                toggleSelectedParticipant,
                preEnabled,
                setPreEnabled,
                activeSinkId,
                setActiveSinkId,
                errorMessage,
                connectionErrorMessage,
                audioMode,
                setAudioMode
            }}
        >
            {children}
        </VideoContext.Provider>
    );
};

export default VideoContextProvider;
