import React, { Fragment } from 'react';
import { Card } from 'reactstrap';
import useTrack from '../../hooks/useTrack';
import VideoTrack from './VideoTrack';
import AudioTrack from './AudioTrack';

const Publication = ({
    publication,
    isDominant,
    isLocal,
    participant,
    videoPriority,
    isVideoEnabled,
    disableAudio,
    isAudioEnabled,
    noSizeLimit,
    fullHeightVideo,
    localIndividual,
    disableVideo,
    isScreenShareParticipant
}) => {
    const track = useTrack(publication);

    if (!track) return null;
    return (
        <Fragment>
            {track.kind === 'video' && !disableVideo && (
                <VideoTrack
                    track={track}
                    isDominant={isDominant}
                    isLocal={isLocal}
                    participant={participant}
                    priority={videoPriority}
                    isAudioEnabled={isAudioEnabled}
                    noSizeLimit={noSizeLimit}
                    fullHeightVideo={fullHeightVideo}
                    localIndividual={localIndividual}
                    disableVideo={disableVideo}
                    isScreenShareParticipant={isScreenShareParticipant}
                />
            )}
            {track.kind === 'audio' && !isVideoEnabled && (
                <Card
                    className={
                        'rounded-0 video-container' +
                        (noSizeLimit && !localIndividual
                            ? ' no-size-limit'
                            : '') +
                        (fullHeightVideo && !localIndividual ? ' h-100' : '') +
                        (localIndividual ? ' video-small' : '')
                    }
                >
                    {participant.identity}
                </Card>
            )}
            {track.kind === 'audio' && !disableAudio && (
                <AudioTrack track={track} />
            )}
        </Fragment>
    );
};

export default Publication;
