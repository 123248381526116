import React from 'react';

const CameraIconOff = props => {
    return (
        <svg
            className={props.className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="E70707"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M3.165 6.432C3.12906 6.39636 3.08318 6.37242 3.03339 6.36331C2.9836 6.35421 2.93222 6.36038 2.886 6.381C2.62202 6.49943 2.39792 6.69168 2.24072 6.93458C2.08352 7.17748 1.99992 7.46067 2 7.75V16.75C2 17.1478 2.15804 17.5294 2.43934 17.8107C2.72064 18.092 3.10218 18.25 3.5 18.25H14.379C14.4285 18.2501 14.4769 18.2355 14.5181 18.208C14.5593 18.1805 14.5914 18.1415 14.6104 18.0957C14.6294 18.05 14.6343 17.9997 14.6246 17.9511C14.6149 17.9025 14.5911 17.858 14.556 17.823L3.165 6.432Z" fill="#E70707"/>
            <path d="M21.737 7.451L18.737 9.162C18.515 9.28702 18.3299 9.46844 18.2005 9.68794C18.0711 9.90743 18.0019 10.1572 18 10.412V14.088C18.0021 14.3428 18.0714 14.5924 18.2008 14.8119C18.3301 15.0314 18.5151 15.2128 18.737 15.338L21.737 17.049C21.9617 17.1819 22.2172 17.2539 22.4783 17.2577C22.7393 17.2616 22.9968 17.1973 23.2254 17.0712C23.454 16.945 23.6457 16.7614 23.7815 16.5384C23.9174 16.3155 23.9927 16.061 24 15.8V8.7C23.9927 8.43903 23.9174 8.1845 23.7815 7.96155C23.6457 7.73861 23.454 7.55499 23.2254 7.42884C22.9968 7.30269 22.7393 7.23838 22.4783 7.24226C22.2172 7.24615 21.9617 7.3181 21.737 7.451Z" fill="#E70707"/>
            <path d="M17.0728 15.659C17.0495 15.6358 17.0311 15.6082 17.0186 15.5778C17.0061 15.5474 16.9997 15.5148 16.9998 15.482V7.74999C16.9998 7.35216 16.8418 6.97063 16.5604 6.68933C16.2791 6.40802 15.8976 6.24999 15.4998 6.24999H7.76779C7.73493 6.2501 7.70237 6.24371 7.67199 6.23118C7.64161 6.21865 7.61401 6.20023 7.59079 6.17699L1.70679 0.292988C1.5425 0.128833 1.32621 0.0269758 1.09502 0.00489704C0.863834 -0.0171817 0.632169 0.0418948 0.439786 0.171988C0.38699 0.207475 0.33776 0.247998 0.292786 0.292988C0.105315 0.480515 0 0.734824 0 0.999988C0 1.26515 0.105315 1.51946 0.292786 1.70699L22.2928 23.707C22.4814 23.8891 22.734 23.9899 22.9962 23.9877C23.2584 23.9854 23.5092 23.8802 23.6946 23.6948C23.88 23.5094 23.9852 23.2586 23.9875 22.9964C23.9897 22.7342 23.8889 22.4816 23.7068 22.293L17.0728 15.659Z" fill="#E70707"/>
        </svg>
    );
};

export default CameraIconOff;