import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import VideoContextProvider from './context/VideoContext';
import ResponsiveContextProvider from './context/ResponsiveContext';
import SidebarContextProvider from './context/SidebarContext';
import { isMobile, isChrome, isAndroid } from './utils';
import UnsupportedBrowser from './components/Video/UnsupportedBrowser';

const connectionOptions = isMobile
    ? {
          bandwidthProfile: {
              video: {
                  mode: 'grid',
                  maxSubscriptionBitrate: 2500000
              }
          },
          logLevel: process.env.DEBUGGING === 'true' ? 'debug' : 'warn',
          maxAudioBitrate: 16000,
          networkQuality: { local: 1, remote: 1 },
          preferredVideoCodecs:
              isChrome && isAndroid
                  ? [{ codec: 'H264' }]
                  : [{ codec: 'VP8', simulcast: false }]
      }
    : {
          bandwidthProfile: {
              video: {
                  mode: 'grid',
                  dominantSpeakerPriority: 'standard',
              }
          },
          dominantSpeaker: true,
          logLevel: process.env.DEBUGGING === 'true' ? 'debug' : 'warn',
          maxAudioBitrate: 16000,
          networkQuality: { local: 1, remote: 1 },
          preferredVideoCodecs: [{ codec: 'VP8', simulcast: false }]
      };

ReactDOM.render(
    <UnsupportedBrowser>
        <ResponsiveContextProvider>
            <SidebarContextProvider>
                <VideoContextProvider options={connectionOptions}>
                    <App />
                </VideoContextProvider>
            </SidebarContextProvider>
        </ResponsiveContextProvider>
    </UnsupportedBrowser>,

    document.getElementById('root')
);
