import React, { useCallback, useRef } from 'react';
import { Button } from 'reactstrap';
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle';
import AudioIcon from '../../assets/Audioicon';
import AudioIconOff from '../../assets/Audioiconoff';

const ToggleAudioButton = () => {
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
    const lastClickTimeRef = useRef(0);

    const toggleAudio = useCallback(() => {
        if (Date.now() - lastClickTimeRef.current > 500) {
            lastClickTimeRef.current = Date.now();
            toggleAudioEnabled();
        }
    }, [toggleAudioEnabled]);

    return (
        <div className="controls-button-container ml-2">
            <Button
                onClick={toggleAudio}
                className="controls-button text-center"
            >
                {isAudioEnabled ? <AudioIcon /> : <AudioIconOff />}
            </Button>
            Mic
        </div>
    );
};

export default ToggleAudioButton;
