import React, { useContext } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { VideoContext } from '../../context/VideoContext';
import { useAudioInputDevices } from '../../hooks/useDevices';
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack';

const AudioInputList = () => {
    const audioInputDevices = useAudioInputDevices();
    const { localTracks } = useContext(VideoContext);

    const localAudioTrack = localTracks.find(track => track.kind === 'audio');
    const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
    const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

    const handleAudioInputChange = ({ target: { value } }) => {
        localAudioTrack.restart({ deviceId: { exact: value } });
    };

    return (
        <FormGroup>
            <Label
                style={{ fontSize: '0.85rem', marginBottom: '4px' }}
            >Audio Input</Label>
            <Input
                type="select"
                name="audioInput"
                onChange={handleAudioInputChange}
                value={localAudioInputDeviceId || ''}
                style={{ fontSize: '0.85rem', fontWeight: '500', padding: '.375rem .75rem .375rem .375rem' }}
            >
                {audioInputDevices.map(device => (
                    <option key={device.deviceId} value={device.deviceId}>
                        {device.label}
                    </option>
                ))}
            </Input>
        </FormGroup>
    );
};

export default AudioInputList;
