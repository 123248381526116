import React from 'react';
import styled from 'styled-components';
import PeopleInfo from './PeopleInfo';

const ParticipantSidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 350px;
    flex-shrink: 0;
    background-color: #e8edf5;
    margin-left: auto;
    height: calc(100% - 50px);
    padding: 10px 20px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const ParticipantContainer = styled.div`
    display: flex;
`;

const ParticipantSidebar = ({ localParticipant, participants }) => {
    return (
        <ParticipantSidebarContainer>
            <ParticipantContainer>
                <PeopleInfo participant={localParticipant} />
            </ParticipantContainer>
            {participants.map((participant, i) => {
                return (
                    <ParticipantContainer key={participant.identity}>
                        <PeopleInfo participant={participant} />
                    </ParticipantContainer>
                );
            })}
        </ParticipantSidebarContainer>
    );
};

export default ParticipantSidebar;
