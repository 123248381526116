import React from 'react';
import { PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';
import styled from 'styled-components';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeviceSelection from '../Video/DeviceSelection';

const SettingsContainer = styled.div`
    position: fixed;
    z-index: 1;
    top: 13px;
    right: ${props => {
        if (props.sidebarNuked) {
            return '10px;';
        }
        const value = props.sidebar ? '360px;' : '60px;';
        return value;
    }}
    cursor: pointer;
    &:hover {
        & > svg {
            color: #ec3192;
        }
    }
`;

const DeviceSelectionPopover = ({ sidebarEnabled, sidebarNuked }) => {
    return (
        <div cl>
            <SettingsContainer
                id="DeviceSelectionPopover"
                sidebar={sidebarEnabled}
                sidebarNuked={sidebarNuked}
            >
                <FontAwesomeIcon icon={faCog} size="2x" color="white" style={{ fontSize: '24px' }} />
            </SettingsContainer>
            <UncontrolledPopover
                placement="left"
                trigger="legacy"
                target="DeviceSelectionPopover"
            >
                <PopoverHeader>Device Selection</PopoverHeader>
                <PopoverBody>
                    <DeviceSelection />
                </PopoverBody>
            </UncontrolledPopover>
        </div>
    );
};

export default DeviceSelectionPopover;
