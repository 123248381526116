import { useEffect, useContext } from 'react';
import { SidebarContext } from '../context/SidebarContext';

const useSubscribe = participant => {
    const { updateChatMessages } = useContext(SidebarContext);
    useEffect(() => {
        const dataTrackSubscribed = track => {
            if (track.kind === 'data') {
                track.on('message', message => {
                    updateChatMessages(message);
                });
            }
        };
        participant.on('trackSubscribed', dataTrackSubscribed);

        return () => {
            participant.off('trackSubscribed', dataTrackSubscribed);
        };
    }, [participant, updateChatMessages]);
};

export default useSubscribe;
