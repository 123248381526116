import React from 'react';

const AudioIcon = props => {
    return (
        <svg
            className={props.className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M12 16C13.1931 15.9987 14.3369 15.5241 15.1805 14.6805C16.0241 13.8369 16.4987 12.6931 16.5 11.5V4.5C16.5 3.30653 16.0259 2.16193 15.182 1.31802C14.3381 0.474106 13.1935 0 12 0C10.8065 0 9.66193 0.474106 8.81802 1.31802C7.97411 2.16193 7.5 3.30653 7.5 4.5V11.5C7.50132 12.6931 7.97585 13.8369 8.81948 14.6805C9.66311 15.5241 10.8069 15.9987 12 16Z" fill="#233659"/>
            <path d="M19 8C18.7348 8 18.4804 8.10536 18.2929 8.29289C18.1054 8.48043 18 8.73478 18 9V11.5C18 13.0913 17.3679 14.6174 16.2426 15.7426C15.1174 16.8679 13.5913 17.5 12 17.5C10.4087 17.5 8.88258 16.8679 7.75736 15.7426C6.63214 14.6174 6 13.0913 6 11.5V9C6 8.73478 5.89464 8.48043 5.70711 8.29289C5.51957 8.10536 5.26522 8 5 8C4.73478 8 4.48043 8.10536 4.29289 8.29289C4.10536 8.48043 4 8.73478 4 9V11.5C4.00258 13.411 4.68806 15.2582 5.93276 16.7083C7.17747 18.1584 8.89944 19.1159 10.788 19.408C10.847 19.4171 10.9008 19.447 10.9397 19.4923C10.9786 19.5376 11 19.5953 11 19.655V23C11 23.2652 11.1054 23.5196 11.2929 23.7071C11.4804 23.8946 11.7348 24 12 24C12.2652 24 12.5196 23.8946 12.7071 23.7071C12.8946 23.5196 13 23.2652 13 23V19.655C13 19.5953 13.0214 19.5376 13.0603 19.4923C13.0992 19.447 13.153 19.4171 13.212 19.408C15.1006 19.1159 16.8225 18.1584 18.0672 16.7083C19.3119 15.2582 19.9974 13.411 20 11.5V9C20 8.73478 19.8946 8.48043 19.7071 8.29289C19.5196 8.10536 19.2652 8 19 8Z" fill="#233659"/>
        </svg>
    );
};

export default AudioIcon;