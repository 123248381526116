import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { VideoContext } from '../../context/VideoContext';
import { postParentMessage } from '../../utils';
import LogoutIcon from '../../assets/Logouticon';

const LeaveMeetingButton = ({ isScreenShared, toggleScreenShare }) => {
    let history = useHistory();
    const location = useLocation();
    const { room } = useContext(VideoContext);

    const parseQueryString = () => {
        return qs.parse(location.search);
    };

    const handleLeaveRoom = () => {
        const { flex } = parseQueryString();

        const isFlex = flex === 'true';

        if (isScreenShared) {
            toggleScreenShare();
        }

        room.disconnect();
        postParentMessage({ currentRoom: null }, isFlex);
        history.push('/');
    };

    return (
        <div className="controls-button-container ml-2">
            <Button onClick={handleLeaveRoom} className="controls-button">
                <LogoutIcon />
            </Button>
            Leave
        </div>
    );
};

export default LeaveMeetingButton;
