import { useState, useRef, useCallback, useEffect } from 'react';
import Video from 'twilio-video';
import { isMobile } from '../utils';

const useRoom = (localTracks, options, preEnabled) => {
    const [room, setRoom] = useState(null);
    const [connectionErrorMessage, setConnectionErrorMessage] = useState(null);
    const localTracksRef = useRef([]);

    function getConnectionErrorMessage(error) {
        let message = '';
        let fatal = false;

        switch (error.name) {
            case 'SignalingConnectionError':
                message = `Twilio was unable to join the video room. Please ensure you have a stable internet connection and reload the page.`;
                fatal = true;
                break;
            case 'SignalingServerBusyError':
                message = `Twilio was unable to join the video room. Please reload the page and try again.`;
                fatal = true;
                break;
            case 'RoomMaxParticipantsExceededError':
                message = `Twilio was unable to join the video room due to maximum participants. Please reload the page and try again.`;
                fatal = false;
                break;
            case 'RoomNotFoundError':
                // no-op using ad-hoc rooms
                break;
            case 'MediaConnectionError':
                message = `Twilio was unable to join the video room. Please ensure you have a stable internet connection and if using a firewall allow traffic to and from Twilio to go through then reload the page.`;
                fatal = true;
                break;
            default:
                message = `Unknown generic error. Please reload the page and try again.`;
                fatal = true;
                break;
        }

        return {
            message: message,
            fatal: fatal
        };
    }

    useEffect(() => {
        localTracksRef.current = localTracks;
    }, [localTracks]);

    const connect = useCallback(
        async token => {
            let newRoom;
            try {
                newRoom = await Video.connect(token, {
                    ...options,
                    tracks: []
                });
            } catch (error) {
                let obj = getConnectionErrorMessage(error);
                if (obj.fatal) {
                    setConnectionErrorMessage(obj.message);
                    return;
                } else {
                    console.log(`ERROR: ${obj.message}`);
                }
            }
            setRoom(newRoom);

            const disconnect = () => {
                newRoom.disconnect();
            };

            newRoom.once('disconnected', () => {
                setRoom(null);
                window.removeEventListener('beforeunload', disconnect);
                if (isMobile) {
                    window.removeEventListener('pagehide', disconnect);
                }
            });

            localTracksRef.current.forEach(track => {
                if (track.kind !== 'video') {
                    newRoom.localParticipant.publishTrack(track, {
                        priority: 'standard'
                    });
                } else if (
                    track.kind === 'video' &&
                    preEnabled.video === true
                ) {
                    newRoom.localParticipant.publishTrack(track, {
                        priority: 'low'
                    });
                }
            });

            // Add a listener to disconnect from the room when a user closes their browser
            window.addEventListener('beforeunload', disconnect);

            if (isMobile) {
                // Add a listener to disconnect from the room when a mobile user closes their browser
                window.addEventListener('pagehide', disconnect);
            }

            window.twilioRoom = newRoom;
        },
        [options, preEnabled]
    );

    return { room, connect, connectionErrorMessage };
};

export default useRoom;
