import React, { useContext } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { VideoContext } from '../../context/VideoContext';
import { useAudioOutputDevices } from '../../hooks/useDevices';

const AudioOutputList = () => {
    const audioOutputDevices = useAudioOutputDevices();
    const { activeSinkId, setActiveSinkId } = useContext(VideoContext);

    const handleAudioOutputChange = ({ target: { value } }) => {
        setActiveSinkId(value);
    };

    return (
        <FormGroup
            style={{ marginBottom: '1.5rem' }}
        >
            <Label
                style={{ fontSize: '0.85rem', marginBottom: '4px' }}
            >Audio Output</Label>
            <Input
                type="select"
                name="audioOutput"
                onChange={handleAudioOutputChange}
                value={activeSinkId}
                style={{ fontSize: '0.85rem', fontWeight: '500', padding: '.375rem .75rem .375rem .375rem' }}
            >
                {audioOutputDevices.map(device => (
                    <option key={device.deviceId} value={device.deviceId}>
                        {device.label}
                    </option>
                ))}
            </Input>
        </FormGroup>
    );
};

export default AudioOutputList;
