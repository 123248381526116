import React from 'react';

const FullscreenIcon = props => {
    return (
        <svg
            className={props.className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M23.7504 2.45299C23.7488 1.87 23.5165 1.31134 23.1042 0.899105C22.692 0.486868 22.1333 0.254574 21.5504 0.252991H15.8184C15.4868 0.252991 15.1689 0.384687 14.9345 0.619107C14.7001 0.853528 14.5684 1.17147 14.5684 1.50299C14.5684 1.83451 14.7001 2.15245 14.9345 2.38687C15.1689 2.62129 15.4868 2.75299 15.8184 2.75299H21.0004C21.0667 2.75299 21.1303 2.77933 21.1771 2.82621C21.224 2.8731 21.2504 2.93669 21.2504 3.00299V6.27099C21.2504 6.60251 21.3821 6.92045 21.6165 7.15487C21.8509 7.38929 22.1688 7.52099 22.5004 7.52099C22.8319 7.52099 23.1498 7.38929 23.3842 7.15487C23.6187 6.92045 23.7504 6.60251 23.7504 6.27099V2.45299Z" fill="#233659"/>
            <path d="M0.25 6.27099C0.25 6.60251 0.381696 6.92046 0.616117 7.15488C0.850537 7.3893 1.16848 7.52099 1.5 7.52099C1.83152 7.52099 2.14946 7.3893 2.38388 7.15488C2.6183 6.92046 2.75 6.60251 2.75 6.27099V2.99999C2.74987 2.96708 2.75624 2.93446 2.76874 2.90401C2.78125 2.87357 2.79964 2.84589 2.82287 2.82257C2.8461 2.79925 2.8737 2.78074 2.9041 2.76812C2.93449 2.75549 2.96709 2.74899 3 2.74899H8.182C8.51352 2.74899 8.83146 2.6173 9.06588 2.38288C9.3003 2.14846 9.432 1.83051 9.432 1.49899C9.432 1.16747 9.3003 0.84953 9.06588 0.615109C8.83146 0.380689 8.51352 0.248993 8.182 0.248993H2.455C1.87201 0.250576 1.31335 0.48287 0.901114 0.895107C0.488877 1.30734 0.256583 1.866 0.255 2.44899L0.25 6.27099Z" fill="#233659"/>
            <path d="M23.7504 21.545V17.726C23.7504 17.3945 23.6187 17.0766 23.3842 16.8421C23.1498 16.6077 22.8319 16.476 22.5004 16.476C22.1688 16.476 21.8509 16.6077 21.6165 16.8421C21.3821 17.0766 21.2504 17.3945 21.2504 17.726V21C21.2504 21.0663 21.224 21.1299 21.1771 21.1768C21.1303 21.2237 21.0667 21.25 21.0004 21.25H15.8184C15.4868 21.25 15.1689 21.3817 14.9345 21.6161C14.7001 21.8505 14.5684 22.1685 14.5684 22.5C14.5684 22.8315 14.7001 23.1495 14.9345 23.3839C15.1689 23.6183 15.4868 23.75 15.8184 23.75H21.5454C22.1301 23.7497 22.6908 23.5174 23.1042 23.1039C23.5177 22.6904 23.7501 22.1297 23.7504 21.545Z" fill="#233659"/>
            <path d="M0.25 21.545C0.251588 22.1289 0.484569 22.6883 0.897886 23.1007C1.3112 23.513 1.87114 23.7448 2.455 23.745H8.182C8.51352 23.745 8.83146 23.6133 9.06588 23.3789C9.3003 23.1445 9.432 22.8265 9.432 22.495C9.432 22.1635 9.3003 21.8456 9.06588 21.6111C8.83146 21.3767 8.51352 21.245 8.182 21.245H3C2.93455 21.245 2.87171 21.2194 2.82497 21.1736C2.77822 21.1278 2.75131 21.0654 2.75 21V17.726C2.75 17.3945 2.6183 17.0766 2.38388 16.8421C2.14946 16.6077 1.83152 16.476 1.5 16.476C1.16848 16.476 0.850537 16.6077 0.616117 16.8421C0.381696 17.0766 0.25 17.3945 0.25 17.726V21.545Z" fill="#233659"/>
            <path d="M16.7734 8.13599H7.22744C6.14827 8.13599 5.27344 9.01082 5.27344 10.09V13.909C5.27344 14.9882 6.14827 15.863 7.22744 15.863H16.7734C17.8526 15.863 18.7274 14.9882 18.7274 13.909V10.09C18.7274 9.01082 17.8526 8.13599 16.7734 8.13599Z" fill="#233659"/>
        </svg>
    );
};

export default FullscreenIcon;
