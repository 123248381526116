import { useState, useCallback } from 'react';
import Video from 'twilio-video';
import { ensureMediaPermissions } from '../utils';
import { DEFAULT_VIDEO_CONSTRAINTS } from '../constants';

const useLocalTracks = () => {
    const [localAudioTrack, setLocalAudioTrack] = useState(null);
    const [localVideoTrack, setLocalVideoTrack] = useState(null);
    const [localDataTrack, setLocalDataTrack] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    function getMediaErrorMessage(error, type) {
        let message = '';
        let fatal = false;
        let deviceType = type === 'audio' ? 'microphone' : 'camera';

        if (type === 'data') {
            deviceType = 'data';
        }

        switch (error.name) {
            case 'NotFoundError':
                message = `Twilio was unable to get access to your ${deviceType}. Please ensure you have a ${deviceType} enabled for the browser in system settings and reload the page.`;
                fatal = type === 'audio' ? true : false;
                break;
            case 'NotAllowedError':
                message = `Twilio was unable to get access to your ${deviceType}. Please allow user media permissions or change your browser's user media permissions settings to grant access to this site and once complete reload the page.`;
                fatal = type === 'audio' ? true : false;
                break;
            case 'TypeError':
                message = `Twilio was unable to get access to your user media. Please ensure this content is being served from a secure context (HTTPS) and that WebRTC is enabled.`;
                fatal = true;
                break;
            case 'NotReadableError':
                message = `Twilio was unable start or capture ${type}. Please ensure all other apps that may be using a ${deviceType} are closed and reload the page or restart the browser.`;
                fatal = true;
                break;
            default:
                message = `Unknown generic error for ${type}.`;
                break;
        }

        return {
            message: message,
            fatal: fatal
        };
    }

    const initializeTracks = useCallback(async () => {
        if (isInitialized) {
            return;
        }

        setIsInitialized(true);

        try {
            await ensureMediaPermissions();
        } catch (error) {
            // should never reach here
            console.log('ERROR: User denied media permissions', error.message);
            return;
        }

        try {
            const audio = await Video.createLocalAudioTrack();
            setLocalAudioTrack(audio);
        } catch (error) {
            let obj = getMediaErrorMessage(error, 'audio');
            if (obj.fatal) {
                setErrorMessage(obj.message);
                return;
            } else {
                console.log(`ERROR: ${obj.message}`);
            }
        }

        try {
            setLocalDataTrack(new Video.LocalDataTrack());
        } catch (error) {
            let obj = getMediaErrorMessage(error, 'data');
            if (obj.fatal) {
                setErrorMessage(obj.message);
                return;
            } else {
                console.log(`ERROR: ${obj.message}`);
            }
        }

        try {
            const video = await Video.createLocalVideoTrack({
                ...DEFAULT_VIDEO_CONSTRAINTS,
                name: 'camera'
            });
            setLocalVideoTrack(video);
        } catch (error) {
            let obj = getMediaErrorMessage(error, 'video');
            if (obj.fatal) {
                setErrorMessage(obj.message);
                return;
            } else {
                console.log(`ERROR: ${obj.message}`);
            }
        }
    }, [isInitialized]);

    const getLocalAudioTrack = useCallback(deviceId => {
        const options = {};

        if (deviceId) {
            options.deviceId = { exact: deviceId };
        }

        return Video.createLocalAudioTrack(options).then(newTrack => {
            setLocalAudioTrack(newTrack);
            return newTrack;
        });
    }, []);

    const getLocalVideoTrack = useCallback(async () => {
        const newTrack = await Video.createLocalVideoTrack({
            ...DEFAULT_VIDEO_CONSTRAINTS,
            name: 'camera'
        });
        setLocalVideoTrack(newTrack);
        return newTrack;
    }, []);

    const localTracks = [
        localAudioTrack,
        localVideoTrack,
        localDataTrack
    ].filter(track => track !== null);

    const releaseTracks = useCallback(() => {
        if (localTracks.forEach) {
            localTracks.forEach(track => track.stop && track.stop());
        }
    }, [localTracks]);

    return {
        localTracks,
        initializeTracks,
        releaseTracks,
        getLocalVideoTrack,
        getLocalAudioTrack,
        errorMessage
    };
};

export default useLocalTracks;
