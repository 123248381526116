import React, { useContext, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { VideoContext } from '../../context/VideoContext';
import { SidebarContext } from '../../context/SidebarContext';
import JoinForm from '../Auth/JoinForm';
import SignalLogo from '../Layout/SignalLogo';
import { postParentMessage } from '../../utils';
import VideoPromo from '../Layout/VideoPromo';

const Join = () => {
    let history = useHistory();
    const location = useLocation();
    const { getToken, connect, setVideoTrackEnabled ,setPreEnabled,preEnabled,setAudioMode } = useContext(VideoContext);
    const { setSidebarNuked } = useContext(SidebarContext);

    const parseQueryString = () => {
        return qs.parse(location.search);
    };

    useEffect(() => {
        const { audioOnly} = parseQueryString();

        if (audioOnly === 'true') {
            setAudioMode(true);
            setVideoTrackEnabled(false);
            setPreEnabled({ ...preEnabled, video: false });
        }
    }, []);

    // * Name and Room come from the <JoinForm> entry
    // * get Token and Room
    // * parse the query string to get the type

    const handleSubmit = async (name, room) => {
        try {
            // * We use NAME and ROOM to get a token
            // * THOUGHT: Generic room name for type: individual
            const { token } = await getToken(name, room);

            if (token) {
                await connect(token);
                
                const { 
                    type = 'individual',
                    flex = 'false', 
                    chat = 'false', 
                } = parseQueryString();

                const isFlex = flex === 'true' || flex === '1';
                const isChat = chat === 'true' || chat === '1';

                if (!isChat) {
                    setSidebarNuked(true);
                }

                postParentMessage({ currentRoom: room }, isFlex);

                return history.push(`/room?type=${type}&flex=${flex}`);
            } else {
                return false;
            }
        } catch (error) {
            console.log('ERROR', error.message);
            return false;
        }
    };

    return (
        <Row className="m-0 pb-5 mb-5">
            <Col
                sm="12"
                md={{ size: 8, offset: 2 }}
                lg={{ size: 6, offset: 3 }}
                xl={{ size: 4, offset: 4 }}
            >
                <SignalLogo />
                <JoinForm
                    handleSubmit={handleSubmit}
                    query={parseQueryString()}
                />
            </Col>
            <VideoPromo hideMobile={false} />
        </Row>
    );
};

export default Join;
