import React from 'react';

const ScreenshareIconOff = props => {
    return (
        <svg
            className={props.className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M19.0003 23.751C19.2656 23.751 19.5199 23.6457 19.7074 23.4581C19.895 23.2706 20.0003 23.0162 20.0003 22.751C20.0003 22.4858 19.895 22.2314 19.7074 22.0439C19.5199 21.8564 19.2656 21.751 19.0003 21.751H12.7503C12.684 21.751 12.6204 21.7247 12.5736 21.6778C12.5267 21.6309 12.5003 21.5673 12.5003 21.501V20C12.5003 19.9337 12.5267 19.8701 12.5736 19.8232C12.6204 19.7763 12.684 19.75 12.7503 19.75H21.0003C21.796 19.75 22.559 19.4339 23.1217 18.8713C23.6843 18.3087 24.0003 17.5457 24.0003 16.75V2.84001C24.0113 2.1654 23.7543 1.51398 23.2856 1.02863C22.8169 0.543283 22.1749 0.263628 21.5003 0.251007H2.50034C1.82576 0.263628 1.18373 0.543283 0.715063 1.02863C0.246397 1.51398 -0.0106371 2.1654 0.000337531 2.84001V16.751C0.000337531 17.5467 0.316408 18.3097 0.879017 18.8723C1.44163 19.4349 2.20469 19.751 3.00034 19.751H10.2503C10.3166 19.751 10.3802 19.7773 10.4271 19.8242C10.474 19.8711 10.5003 19.9347 10.5003 20.001V21.501C10.5003 21.5673 10.474 21.6309 10.4271 21.6778C10.3802 21.7247 10.3166 21.751 10.2503 21.751H5.00034C4.73512 21.751 4.48077 21.8564 4.29323 22.0439C4.10569 22.2314 4.00034 22.4858 4.00034 22.751C4.00034 23.0162 4.10569 23.2706 4.29323 23.4581C4.48077 23.6457 4.73512 23.751 5.00034 23.751H19.0003ZM2.00034 2.84001C1.98979 2.69587 2.03628 2.55337 2.12981 2.44319C2.22334 2.33302 2.3564 2.264 2.50034 2.25101H21.5003C21.6443 2.264 21.7773 2.33302 21.8709 2.44319C21.9644 2.55337 22.0109 2.69587 22.0003 2.84001V14.751C22.0003 14.8836 21.9477 15.0108 21.8539 15.1046C21.7601 15.1983 21.6329 15.251 21.5003 15.251H2.50034C2.36773 15.251 2.24055 15.1983 2.14678 15.1046C2.05302 15.0108 2.00034 14.8836 2.00034 14.751V2.84001Z" fill="#233659"/>
            <path d="M15.5 6.25101C15.5 5.98579 15.3946 5.73144 15.2071 5.5439C15.0196 5.35636 14.7652 5.25101 14.5 5.25101H8.5C8.23478 5.25101 7.98043 5.35636 7.79289 5.5439C7.60536 5.73144 7.5 5.98579 7.5 6.25101V11.251C7.5 11.5162 7.60536 11.7706 7.79289 11.9581C7.98043 12.1457 8.23478 12.251 8.5 12.251H14.5C14.7652 12.251 15.0196 12.1457 15.2071 11.9581C15.3946 11.7706 15.5 11.5162 15.5 11.251V10.931C15.5 10.8922 15.509 10.8539 15.5264 10.8192C15.5437 10.7845 15.569 10.7543 15.6 10.731C15.6321 10.7078 15.6692 10.6926 15.7084 10.6867C15.7475 10.6808 15.7875 10.6843 15.825 10.697L17.167 11.144C17.2045 11.1567 17.2445 11.1603 17.2836 11.1543C17.3228 11.1484 17.3599 11.1332 17.392 11.11C17.4229 11.0866 17.4481 11.0564 17.4654 11.0217C17.4827 10.987 17.4918 10.9488 17.492 10.91V6.58401C17.492 6.5452 17.483 6.50692 17.4656 6.4722C17.4482 6.43749 17.423 6.40729 17.392 6.38401C17.361 6.36072 17.3249 6.34498 17.2867 6.33804C17.2485 6.3311 17.2093 6.33314 17.172 6.34401L15.812 6.74401C15.7747 6.75487 15.7355 6.75692 15.6973 6.74997C15.6591 6.74303 15.623 6.72729 15.592 6.70401C15.561 6.68072 15.5358 6.65052 15.5184 6.61581C15.501 6.5811 15.492 6.54282 15.492 6.50401L15.5 6.25101Z" fill="#233659"/>
        </svg>
    );
};

export default ScreenshareIconOff;