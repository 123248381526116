import React, { useContext, useEffect } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { VideoContext } from '../../context/VideoContext';
import { SidebarContext } from '../../context/SidebarContext';
import Group from '../Video/Group';
import Individual from '../Video/Individual';
import RoomEmpty from './RoomEmpty';
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle';

const Room = () => {
    const location = useLocation();
    const { room, preEnabled } = useContext(VideoContext);
    const { setSidebarEnabled, sidebarEnabled, sidebarNuked } = useContext(
        SidebarContext
    );
    const [, toggleAudioEnabled] = useLocalAudioToggle();

    useEffect(() => {
        if (preEnabled.audio === false) {
            toggleAudioEnabled();
        }
    }, [preEnabled, toggleAudioEnabled]);

    const getExperience = () => {
        const { type } = qs.parse(location.search);
        if (type === 'individual') {
            return (
                <Individual
                    sidebarEnabled={sidebarEnabled}
                    setSidebarEnabled={setSidebarEnabled}
                    sidebarNuked={sidebarNuked}
                />
            );
        }
        if (type === 'group') {
            return (
                <Group
                    sidebarEnabled={sidebarEnabled}
                    setSidebarEnabled={setSidebarEnabled}
                    sidebarNuked={sidebarNuked}
                />
            );
        }

        return <RoomEmpty />;
    };

    return room ? getExperience() : <RoomEmpty />;
};

export default Room;
