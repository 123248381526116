import React, { useContext } from 'react';
import useMainSpeaker from '../../hooks/useMainSpeaker';
import { VideoContext } from '../../context/VideoContext';
import Participant from './Participant';

const MainParticipant = ({
    disableAudio,
    disableVideo,
    isScreenShareParticipant
}) => {
    const mainParticipant = useMainSpeaker();
    const { selectedParticipant } = useContext(VideoContext);

    const videoPriority =
        mainParticipant === selectedParticipant || isScreenShareParticipant
            ? 'high'
            : null;

    return (
        <Participant
            participant={mainParticipant}
            videoPriority={videoPriority}
            noSizeLimit
            fullHeightVideo
            isScreenShareParticipant={isScreenShareParticipant}
            disableAudio={disableAudio}
            disableVideo={disableVideo}
        />
    );
};

export default MainParticipant;
