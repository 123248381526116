import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Button, Input } from 'reactstrap';
import styled from 'styled-components';
import { faPaperPlane, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isIOS } from '../../utils';

const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 350px;
    background-color: #e8edf5;
    flex-shrink: 0;
    height: ${() => (isIOS ? 'calc(100% - 115px)' : 'calc(100% - 50px)')};

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const MessageInput = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-self: flex-end;
    border-top: 1px solid #ec3192;
`;

const ChatMessages = styled.div`
    overflow: auto;
    flex-grow: 1;
    padding: 0 12px !important;
`;

const ChatTime = styled.span`
    font-size: 10px;
    position: absolute;
    top: 5px;
    right: 5px;
`;

const ChatMessageContainer = styled.div`
    display: block;
`;

const ChatMessage = styled.div`
    position: relative;
    vertical-align: top;
    display: inline-block;
    margin-bottom: 7px;
    padding: 8px 12px;
    font-size: 13px;
    width: 100%;
    border-radius: 8px 8px 8px 0px;
    background-color: #ffffff;
    color: #000000;

    p {
        margin-top: 4px;
    }
`;

const IconContainer = styled.div`
    display: inline-block;
`;

const Chat = ({ chatMessages, sendMessage }) => {
    const [message, setMessage] = useState('');
    const onChangeMessage = ({ target: { value } }) => {
        setMessage(value);
    };
    const sendChatMessage = () => {
        if (message) {
            sendMessage(message);
            setMessage('');
        }
    };

    const handleKeyPress = e => {
        if (e.key === 'Enter' && !(e.shiftKey || e.ctrlKey)) {
            sendChatMessage();
            e.preventDefault();
        }
    };

    return (
        <ChatContainer>
            <ChatMessages className="p-0 pl-2 pr-2">
                {chatMessages.length === 0 && (
                    <p className="no-messages">There are no chat messages for this room.</p>
                )}
                {chatMessages.map((message, i) => {
                    return (
                        <ChatMessageContainer key={i}>
                            <ChatMessage key={i}>
                                <small>{message.identity}</small>
                                <p className="mb-0 text-break">
                                    {message.content}{' '}
                                    <ChatTime>
                                        {format(
                                            parseISO(message.sent),
                                            'hh:mm a'
                                        )}
                                    </ChatTime>
                                </p>
                            </ChatMessage>
                        </ChatMessageContainer>
                    );
                })}
            </ChatMessages>
            <MessageInput>
                <Input
                    className="chat-input p-2 pl-2"
                    type="textarea"
                    value={message}
                    onChange={onChangeMessage}
                    onKeyPress={handleKeyPress}
                />
                <Button
                    block
                    onClick={sendChatMessage}
                    className="p-0 chat-button"
                >
                    <FontAwesomeIcon
                        icon={faPaperPlane}
                        size="1x"
                        className="branding-pink"
                    />
                </Button>
            </MessageInput>
        </ChatContainer>
    );
};

export default Chat;
