import React from 'react';
import styled from 'styled-components';
import ProgrammableVideoLogo from '../../assets/ProgrammableVideoLogo';

const Promo = styled.div`
  position: absolute;
  bottom: 4%;
  left: ${(props) => (props.hideMobile ? "2.5%" : "3.5%")};
  width: 250px;
  color: #273658;
  font-weight: 600;
  font-size: 13px;
  display: ${(props) => {
    if (props.hideMobile) {
      return "none";
    }

    return "block";
  }};

  svg {
    float: left;
    margin: 2px 12px 0 0;

    path {
      fill: #273658;
    }
  }

  a {
    color: #273658;
    display: inline-block;
    border-bottom: solid 1px white;
    font-weight: 600;

    &:hover {
      text-decoration: none;
    }
  }

  @media screen and (min-width: 768px) {
    display: ${(props) => {
      if (props.sidebarEnabled && !props.sidebarNuked) {
        return "none";
      }

      return "block";
    }};
  }

  @media screen and (min-width: 1200px) {
    display: block;
  }
`;

const VideoPromo = props => {
  return (
    <Promo {...props}>
      <ProgrammableVideoLogo />
      Powered by <a href="https://www.twilio.com/video" target="_blank" rel="noopener noreferrer">Twilio Programmable Video</a>
    </Promo>
  );
};

export default VideoPromo;
