import React, { useState, useEffect, useContext } from 'react';
import {
    Card,
    CardBody,
    CardFooter,
    Alert,
    Form,
    FormGroup,
    Input,
    Button,
    Collapse
} from 'reactstrap';
import { VideoContext } from '../../context/VideoContext';
import JoinControls from './JoinButtons';
import DeviceSelection from '../Video/DeviceSelection';
import LocalPreview from '../Video/LocalPreview';

const JoinForm = ({ handleSubmit, query }) => {
    const [isNameProvided, setIsNameProvided] = useState(true);
    // Whether the collapsible is currently open or not
    const [isOpen, setIsOpen] = useState(false);

    // Whether the collapsible has opened at least once
    const [hasOpened, setHasOpened] = useState(false);

    // Whether Join Room button is enabled or not
    const [isJoinRoomEnabled, setIsJoinRoomEnabled] = useState(true);

    const [state, setState] = useState({
        name: '',
        room: '',
        pin: '',
        error: '',
        success: ''
    });

    const toggle = () => {
        setIsOpen(!isOpen);

        if (isOpen) {
            setHasOpened(true);
        }
    };

    const {
        validatePin,
        errorMessage,
        connectionErrorMessage
    } = useContext(VideoContext);

    useEffect(() => {
        if (!state.room) {
            if (!query.name && query.room && query.pin) {
                setIsNameProvided(false);
                setState({
                    ...state,
                    room: query.room,
                    pin: query.pin
                });
            }
            if (query.name && query.room && query.pin) {
                setState({
                    ...state,
                    room: query.room,
                    name: query.name,
                    pin: query.pin
                });
            }
        }
    }, [query, state]);

    const handleChange = ({ target: { name, value } }) => {
        setState({ ...state, [name]: value });
    };

    const onFormSubmit = async evt => {
        evt.preventDefault();
        setIsJoinRoomEnabled(false);
        const { name, room, pin } = state;
        if (!name) {
            setIsJoinRoomEnabled(true);
            return setState({ ...state, error: 'Please enter your name.' });
        }

        if (!pin) {
            setIsJoinRoomEnabled(true);
            return setState({ ...state, error: 'Please enter the PIN.' });
        }

        if (!name || !room || !pin) {
            setIsJoinRoomEnabled(true);
            return setState({ ...state, error: 'Please fill out form.' });
        }

        const { roomValidation } = await validatePin(room, pin);

        if (!roomValidation) {
            setIsJoinRoomEnabled(true);
            return setState({ ...state, error: 'Invalid credentials' });
        }

        const result = await handleSubmit(name, room);
        if (result) {
            //setState({ ...state, success: 'Form successfully submitted' });
        } else {
            setIsJoinRoomEnabled(true);
            return setState({
                ...state,
                error: 'Internal error please reload and try again.'
            });
        }
    };

    return (
        <Card className="p-3 border-0 rounded-xl shadow">
            <LocalPreview />

            {!errorMessage && (
                <CardBody className="pb-0 pl-2 pr-2 pt-2">
                    <JoinControls />
                    <Button
                        className="brand-button no-underline"
                        block
                        onClick={toggle}
                        style={{ marginBottom: '1rem', fontSize: '0.85rem' }}
                    >
                        {isOpen
                            ? 'Hide Settings'
                            : 'Show Settings'}
                    </Button>
                    <Collapse isOpen={isOpen}>
                        {(isOpen || hasOpened) && <DeviceSelection />}
                    </Collapse>

                    <Form onSubmit={onFormSubmit}>
                        {!isNameProvided && (
                            <FormGroup className="m-0 mb-3">
                                <Input
                                    type="text"
                                    name="name"
                                    value={state.name}
                                    placeholder="Your name"
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        )}
                        <Button
                            id="join-room-button"
                            type="submit"
                            className="brand-button2 no-underline mb-2 rounded-pill"
                            block
                            disabled={!isJoinRoomEnabled}
                        >
                            Join Room
                        </Button>
                    </Form>
                </CardBody>
            )}
            {(errorMessage || connectionErrorMessage) && (
                <CardFooter className="bg-white border-top-0 pl-2 pr-2 pb-2 pt-1">
                    <Alert style={{ marginBottom: '0' }} color="danger">
                        <p>{errorMessage}</p>
                        <p>{connectionErrorMessage}</p>
                        <p>
                            If the issue persists please contact us using “Chat
                            with Concierge” from the platform, so that we can
                            cancel your 1:1.
                        </p>
                    </Alert>
                </CardFooter>
            )}
            {state.error && (
                <CardFooter className="p-2 bg-white border-top-0">
                    <Alert style={{ marginBottom: '0' }} color="danger">
                        {state.error}
                    </Alert>
                </CardFooter>
            )}
            {state.success && (
                <CardFooter className="p-2 bg-white border-top-0">
                    <Alert className="mb-0" color="success">
                        {state.success}
                    </Alert>
                </CardFooter>
            )}
        </Card>
    );
};

export default JoinForm;
