import React, { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import SignalLogo from '../Layout/SignalLogo';
import { VideoContext } from '../../context/VideoContext';

const Index = () => {
    const { releaseTracks } = useContext(VideoContext);
    releaseTracks();

    return (
        <Row className="m-0">
            <Col sm="12" md={{ size: 8, offset: 2 }}>
                <SignalLogo hexColor="#000000" />
                <div className="success-message">
                    <h1>Thank you for using Twilio <span>Programmable Video</span></h1>
                    <p>We hope you are enjoying SIGNAL!</p>
                </div>
            </Col>
        </Row>
    );
};

export default Index;
