import React from 'react';
import styled from 'styled-components';

const LogoContainer = styled.div`
    width: 2em;
    height: 2em;
    margin: 1.5em auto;
`;

const SignalLogo = props => {
    return (
        <LogoContainer>
            <svg
                fill="#061A40"
                height="100%"
                viewBox="0 0 34 34"
                width="100%"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="m24.2056479 12.6719555-17.69659627 17.7184635c1.78288346 1.4104469 3.83283647 2.4447227 6.02591797 3.0405216 0 0 3.863692-3.8961876 3.8478753-3.8961876l6.5587604-6.5709224c2.7349993-2.7313293 3.2566923-6.9785061 1.2640426-10.2918751zm6.1651571-6.16879058-3.1799422 3.18382493c3.5709527 4.99692285 2.998698 11.84848095-1.3514236 16.18210695l-8.1132088 8.1309032c9.3725842-.3935648 16.6516545-8.3198974 16.2585696-17.7039254-.1493515-3.5628517-1.4136534-6.98863074-3.613995-9.79290968zm-22.22407275 1.63578611 8.12876625-8.13895103c-9.37880714.39927615-16.65865532 8.33521421-16.25986608 17.7254728.15116653 3.5571404 1.4128755 6.9777272 3.60751274 9.7794101l3.19990762-3.1838249c-3.57847217-4.9904327-3.01788549-11.8414715 1.32367947-16.18210697zm2.90561255 2.91331407c-2.72514616 2.7313292-3.24606135 6.9681217-1.26404257 10.2799331l11.52754987-11.541625 2.9652495-2.97302374 3.1799422-3.18382493c-1.7743269-1.40837005-3.8123525-2.44628036-5.9942845-3.0524636l-3.8953255 3.9000817z"></path>
            </svg>
        </LogoContainer>
    );
};

export default SignalLogo;
