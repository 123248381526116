import React, { Fragment } from 'react';
import VideoInputList from '../Video/VideoInputList';
import AudioInputList from '../Video/AudioInputList';
import AudioOutputList from '../Video/AudioOutputList';

const DeviceSelection = () => {
    return (
        <Fragment>
            <VideoInputList />
            <AudioInputList />
            {!!Audio.prototype.setSinkId && (<AudioOutputList />)}
        </Fragment>
    );
};

export default DeviceSelection;
