import React, { useCallback, useRef } from 'react';
import { Button } from 'reactstrap';
import useLocalVideoToggle from '../../hooks/useLocalVideoToggle';
import CameraIcon from '../../assets/Cameraicon';
import CameraIconOff from '../../assets/Cameraiconoff';

const ToggleVideoButton = () => {
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const lastClickTimeRef = useRef(0);

    const toggleVideo = useCallback(() => {
        if (Date.now() - lastClickTimeRef.current > 500) {
            lastClickTimeRef.current = Date.now();
            toggleVideoEnabled();
        }
    }, [toggleVideoEnabled]);

    return (
        <div className="controls-button-container ml-2">
            <Button onClick={toggleVideo} className="controls-button">
                {isVideoEnabled ? <CameraIcon /> : <CameraIconOff />}
            </Button>
            Cam
        </div>
    );
};

export default ToggleVideoButton;
