import React from 'react';
import styled from 'styled-components';
import useScreenShareToggle from '../../hooks/useScreenShareToggle';
import ToggleScreenShareButton from './ToggleScreenShareButton';
import ToggleAudioButton from './ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton';
import LeaveMeetingButton from './LeaveMeetingButton';

const ControlsContainer = styled.div`
    display: flex;
    margin: 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 960px) {
        justify-content: center;
    }
`;

const ButtonsContainer = styled.div`
    background-color: #20419a;
    padding: 10px;
    display: flex;
`;

const TextContainer = styled.div`
    background-color: #20419a;
    padding: 10px;
    height: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 12px;

    @media (max-width: 960px) {
        display: none;
    }
`;

const RightAlign = styled.span`
    text-align: right;
`;

const Link = styled.a`
    color: #fff;
    text-decoration: underline;

    &:hover {
        color: var(--branding-light-blue);
    }
`;

const Image = styled.img`
    margin-left: 12px;
`;

const Controls = ({ screenShareParticipant }) => {
    const [isScreenShared, toggleScreenShare] = useScreenShareToggle();
    return (
        <ControlsContainer>
            <TextContainer>
                <span>
                    powered by Twilio Video,
                    <br />
                    <Link href="">see how we built it</Link>
                </span>
            </TextContainer>

            <ButtonsContainer>
                <ToggleScreenShareButton
                    screenShareParticipant={screenShareParticipant}
                    isScreenShared={isScreenShared}
                    toggleScreenShare={toggleScreenShare}
                />
                <ToggleAudioButton />
                <ToggleVideoButton />
                <LeaveMeetingButton
                    isScreenShared={isScreenShared}
                    toggleScreenShare={toggleScreenShare}
                />
            </ButtonsContainer>

            <TextContainer>
                <RightAlign>
                    Twilio Creators
                    <br />
                    Summit 2020
                </RightAlign>
                <Image src={require('../../images/signal-logo.svg')} />
            </TextContainer>
        </ControlsContainer>
    );
};

export default Controls;
