import { useState, useEffect } from 'react';
import { ensureMediaPermissions } from '../utils';

export const useDevices = () => {
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        const getDevices = () =>
            ensureMediaPermissions().then(() =>
                navigator.mediaDevices
                    .enumerateDevices()
                    .then(devices => setDevices(devices))
            );
        navigator.mediaDevices.addEventListener('devicechange', getDevices);
        getDevices();

        return () => {
            navigator.mediaDevices.removeEventListener(
                'devicechange',
                getDevices
            );
        };
    }, []);

    return devices;
};

export const useAudioInputDevices = () => {
    const devices = useDevices();
    return devices.filter(device => device.kind === 'audioinput');
};

export const useVideoInputDevices = () => {
    const devices = useDevices();
    return devices.filter(device => device.kind === 'videoinput');
};

export const useAudioOutputDevices = () => {
    const devices = useDevices();
    return devices.filter(device => device.kind === 'audiooutput');
};
