import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Index from './components/Pages/Index';
import Join from './components/Pages/Join';
import Room from './components/Pages/Room';

function App() {
    return (
        <Router>
            <Layout>
                <Switch>
                    <Route exact path="/">
                        <Index />
                    </Route>
                    <Route exact path="/join">
                        <Join />
                    </Route>
                    <Route exact path="/room">
                        <Room />
                    </Route>
                </Switch>
            </Layout>
        </Router>
    );
}

export default App;
