import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import bg from '../../images/bg.png';
import bg2 from '../../images/bg2.png';

const LayoutContainer = styled.div`
    display: table;
    background: #F5F7FF;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
`;

const Layout = ({ children }) => {
    const location = useLocation();

    const getBackground = location => {
        const query = qs.parse(location.search);
        if (query.type === 'group') {
            return bg2;
        } else if (query.type === 'individual') {
            return bg;
        } else {
            return bg;
        }
    };

    return (
        <LayoutContainer background={getBackground(location)}>
            {children}
        </LayoutContainer>
    );
};

export default Layout;
