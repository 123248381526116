import React from 'react';

const AudioIconOff = props => {
    return (
        <svg
            className={props.className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7.5 6.08579V4.5C7.5 3.30653 7.97411 2.16193 8.81802 1.31802C9.66193 0.474106 10.8065 0 12 0C13.1935 0 14.3381 0.474106 15.182 1.31802C16.0259 2.16193 16.5 3.30653 16.5 4.5V11.5C16.4989 12.4755 16.1815 13.418 15.6047 14.1905L16.6751 15.2609C17.5285 14.2 18 12.8743 18 11.5V9C18 8.73478 18.1054 8.48043 18.2929 8.29289C18.4804 8.10536 18.7348 8 19 8C19.2652 8 19.5196 8.10536 19.7071 8.29289C19.8946 8.48043 20 8.73478 20 9V11.5C19.9974 13.398 19.3213 15.2329 18.0927 16.6785L23.7071 22.2929C24.0976 22.6834 24.0976 23.3166 23.7071 23.7071C23.3166 24.0976 22.6834 24.0976 22.2929 23.7071L20.9126 22.3268C20.9021 22.3153 20.8913 22.304 20.8802 22.2929L2.70125 4.11394C2.69015 4.10284 2.67886 4.09206 2.66738 4.08159L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893ZM4.18268 8.4238L6 10.2411V11.5C6 13.0913 6.63214 14.6174 7.75736 15.7426C8.88258 16.8679 10.4087 17.5 12 17.5C12.3882 17.5 12.7725 17.4624 13.148 17.3892L14.7644 19.0055C14.2648 19.1901 13.7451 19.3255 13.212 19.408C13.153 19.4171 13.0992 19.447 13.0603 19.4923C13.0214 19.5376 13 19.5953 13 19.655V23C13 23.2652 12.8946 23.5196 12.7071 23.7071C12.5196 23.8946 12.2652 24 12 24C11.7348 24 11.4804 23.8946 11.2929 23.7071C11.1054 23.5196 11 23.2652 11 23V19.655C11 19.5953 10.9786 19.5376 10.9397 19.4923C10.9008 19.447 10.847 19.4171 10.788 19.408C8.89944 19.1159 7.17747 18.1584 5.93276 16.7083C4.68806 15.2582 4.00258 13.411 4 11.5V9C4 8.79232 4.0646 8.5913 4.18268 8.4238ZM11.7518 15.9929C10.6492 15.9309 9.60441 15.4654 8.81948 14.6805C8.03455 13.8956 7.56914 12.8508 7.50711 11.7482L11.7518 15.9929Z" fill="#E70707"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
            {/* <path fillRule="evenodd" clipRule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7.5 6.08579V4.5C7.5 3.30653 7.97411 2.16193 8.81802 1.31802C9.66193 0.474106 10.8065 0 12 0C13.1935 0 14.3381 0.474106 15.182 1.31802C16.0259 2.16193 16.5 3.30653 16.5 4.5V11.5C16.4989 12.4755 16.1815 13.418 15.6047 14.1905L16.6751 15.2609C17.5285 14.2 18 12.8743 18 11.5V9C18 8.73478 18.1054 8.48043 18.2929 8.29289C18.4804 8.10536 18.7348 8 19 8C19.2652 8 19.5196 8.10536 19.7071 8.29289C19.8946 8.48043 20 8.73478 20 9V11.5C19.9974 13.398 19.3213 15.2329 18.0927 16.6785L23.7071 22.2929C24.0976 22.6834 24.0976 23.3166 23.7071 23.7071C23.3166 24.0976 22.6834 24.0976 22.2929 23.7071L20.9126 22.3268C20.9021 22.3153 20.8913 22.304 20.8802 22.2929L2.70125 4.11394C2.69015 4.10284 2.67886 4.09206 2.66738 4.08159L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893ZM4.18268 8.4238L6 10.2411V11.5C6 13.0913 6.63214 14.6174 7.75736 15.7426C8.88258 16.8679 10.4087 17.5 12 17.5C12.3882 17.5 12.7725 17.4624 13.148 17.3892L14.7644 19.0055C14.2648 19.1901 13.7451 19.3255 13.212 19.408C13.153 19.4171 13.0992 19.447 13.0603 19.4923C13.0214 19.5376 13 19.5953 13 19.655V23C13 23.2652 12.8946 23.5196 12.7071 23.7071C12.5196 23.8946 12.2652 24 12 24C11.7348 24 11.4804 23.8946 11.2929 23.7071C11.1054 23.5196 11 23.2652 11 23V19.655C11 19.5953 10.9786 19.5376 10.9397 19.4923C10.9008 19.447 10.847 19.4171 10.788 19.408C8.89944 19.1159 7.17747 18.1584 5.93276 16.7083C4.68806 15.2582 4.00258 13.411 4 11.5V9C4 8.79232 4.0646 8.5913 4.18268 8.4238ZM11.7518 15.9929C10.6492 15.9309 9.60441 15.4654 8.81948 14.6805C8.03455 13.8956 7.56914 12.8508 7.50711 11.7482L11.7518 15.9929Z" fill="#E70707"/> */}
        </svg>
    );
};

export default AudioIconOff;
