import React, { useRef, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { Card } from 'reactstrap';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff';
import useVideoTrackDimensions from '../../hooks/useVideoTrackDimensions';

const Video = styled.video`
    height: 100%;
    width: 100%;
    &.video-switched-off {
        filter: brightness(0);
    }
    &.video-share {
        transform: none;
        object-fit: contain;
    }
`;

const VideoInfo = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0px;
    color: #ffffff;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 4px 8px;
    line-height: 1;
    border-radius: 0px 3px 0 0;
`;

const VideoTrack = ({
    track,
    participant,
    priority,
    isDominant,
    isLocal,
    noSizeLimit,
    isAudioEnabled,
    fullHeightVideo,
    isPreview,
    isScreenShareParticipant
}) => {
    const isVideoSwitchedOff = useIsTrackSwitchedOff(track);
    const dimensions = useVideoTrackDimensions(track);
    const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);

    const trackRef = useRef(null);
    useEffect(() => {
        const element = trackRef.current;
        element.muted = true;
        if (track.setPriority && priority) {
            track.setPriority(priority);
        }
        track.attach(element);

        return () => {
            track.detach(element);
            if (track.setPriority && priority) {
                track.setPriority(null);
            }
        };
    }, [track, priority]);

    const videoContainerClasses =
        (noSizeLimit ? ' no-size-limit h-100' : ' video-small') +
        (isDominant ? ' dominant-border' : '') +
        (isPreview ? ' video-preview' : '');

    const videoClasses =
        (fullHeightVideo ? ' h-100' : '') +
        (isVideoSwitchedOff ? ' video-switched-off' : '') +
        (isScreenShareParticipant ? ' video-share' : '') +
        (isPortrait ? ' video-contain' : '') +
        (!isPortrait ? ' video-cover' : '') +
        (isLocal || isPreview ? ' video-flip' : '');

    return (
        <Fragment>
            <Card
                className={'rounded-0 video-container' + videoContainerClasses}
            >
                <Video
                    id={isLocal || isPreview ? 'local-video' : 'remote-video'}
                    ref={trackRef}
                    className={videoClasses}
                />
                {participant && <VideoInfo>{participant.identity}</VideoInfo>}
            </Card>
        </Fragment>
    );
};

export default VideoTrack;
