import React, { createContext, useState, useCallback } from 'react';

export const SidebarContext = createContext(null);

const SidebarContextProvider = ({ children }) => {
    const [chatMessages, setChatMessages] = useState([]);
    const [sidebarEnabled, setSidebarEnabled] = useState(true);
    const [sidebarNuked, setSidebarNuked] = useState(false);

    const updateChatMessages = useCallback(message => {
        const newMessage = JSON.parse(message);
        setChatMessages(prev => {
            const updatedMessages = [...prev, newMessage];
            const unique = Array.from(
                new Set(updatedMessages.map(message => message.id))
            );
            return unique.map(id =>
                updatedMessages.find(message => message.id === id)
            );
        });
    }, []);

    return (
        <SidebarContext.Provider
            value={{
                chatMessages,
                updateChatMessages,
                sidebarEnabled,
                setSidebarEnabled,
                sidebarNuked,
                setSidebarNuked
            }}
        >
            {children}
        </SidebarContext.Provider>
    );
};

export default SidebarContextProvider;
