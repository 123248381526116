import { useState, useContext, useEffect, useCallback } from 'react';
import { VideoContext } from '../context/VideoContext';
import { SidebarContext } from '../context/SidebarContext';

const useChatFeature = () => {
    const [dataTrack, setDataTrack] = useState(null);
    const {
        room: { localParticipant }
    } = useContext(VideoContext);
    const { chatMessages, updateChatMessages } = useContext(SidebarContext);
    const identity = localParticipant.identity;

    useEffect(() => {
        const dataTrackPublished = publication => {
            if (publication.kind === 'data') {
                setDataTrack(publication.track);
            }
        };
        localParticipant.on('trackPublished', dataTrackPublished);
        return () => {
            localParticipant.off('trackPublished', dataTrackPublished);
        };
    }, [localParticipant]);

    const sendMessage = useCallback(
        content => {
            if (dataTrack) {
                const message = JSON.stringify({
                    id: Math.floor(Math.random() * 1000000),
                    content,
                    identity,
                    sent: new Date()
                });
                dataTrack.send(message);
                updateChatMessages(message);
            }
        },
        [dataTrack, updateChatMessages, identity]
    );

    return { chatMessages, sendMessage };
};

export default useChatFeature;
