import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import {
    faMicrophoneSlash,
    faMicrophone,
    faVideoSlash,
    faVideo
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VideoContext } from '../../context/VideoContext';

const JoinButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
`;

const JoinButtonsAudio = styled.div`
    width: 20px;
    margin-left: 4px;
    margin-right: -4px;
`;

const JoinButtonsVideo = styled.div`
    margin-left: 4px;
    margin-right: -4px;
`;

const JoinButtonLabel = styled.div`
    font-size: 0.85rem;
`;

const JoinButtons = () => {
    const {
        localTracks,
        preEnabled,
        setPreEnabled,
        setVideoTrackEnabled,
        setAudioMode,
    } = useContext(VideoContext);

    const handleSetPreEnabled = type => () => {
        if (type === 'video') {
            setAudioMode(false);
            setVideoTrackEnabled(!preEnabled[type]);
        }
        setPreEnabled({ ...preEnabled, [type]: !preEnabled[type] });
    };

    const videoTrackAvailable = !!localTracks.find(track => track.name === 'camera');

    return (
        <JoinButtonsContainer>
            <Button
                className="brand-button join-control-button"
                onClick={handleSetPreEnabled('audio')}
            >
                <JoinButtonLabel>{preEnabled.audio ? 'Disable' : 'Enable'} Audio</JoinButtonLabel>
                <JoinButtonsAudio>
                    {preEnabled.audio ? (
                        <FontAwesomeIcon icon={faMicrophone} size="1x" />
                    ) : (
                        <FontAwesomeIcon icon={faMicrophoneSlash} size="1x" />
                    )}
                </JoinButtonsAudio>
            </Button>
            <Button
                className="brand-button join-control-button"
                onClick={handleSetPreEnabled('video')}
                disabled={!videoTrackAvailable}
            >
                {videoTrackAvailable ? (
                    <JoinButtonLabel>{preEnabled.video ? 'Disable' : 'Enable'} Video</JoinButtonLabel>
                ) : (
                    <JoinButtonLabel>Video Unavailable</JoinButtonLabel>
                )}
                <JoinButtonsVideo>
                    {(videoTrackAvailable && preEnabled.video) ? (
                        <FontAwesomeIcon icon={faVideo} size="1x" />
                    ) : (
                        <FontAwesomeIcon icon={faVideoSlash} size="1x" />
                    )}
                </JoinButtonsVideo>
            </Button>
        </JoinButtonsContainer>
    );
};

export default JoinButtons;
