import React from 'react';

const ProgrammableVideoLogo = props => {
    return (
        <svg
            className={props.className}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M19.5 26H2.5C1.83696 26 1.20107 25.7366 0.732233 25.2678C0.263392 24.7989 0 24.163 0 23.5L0 8.5C0 7.83696 0.263392 7.20107 0.732233 6.73223C1.20107 6.26339 1.83696 6 2.5 6H19.5C20.163 6 20.7989 6.26339 21.2678 6.73223C21.7366 7.20107 22 7.83696 22 8.5V12.38L28.38 9.19C28.7615 8.99882 29.1856 8.90848 29.612 8.92759C30.0383 8.9467 30.4526 9.07462 30.8155 9.29917C31.1784 9.52372 31.4777 9.83742 31.6851 10.2104C31.8924 10.5834 32.0008 11.0033 32 11.43V20.58C32.0028 21.0064 31.896 21.4264 31.6899 21.7997C31.4838 22.173 31.1852 22.4871 30.8229 22.7119C30.4606 22.9367 30.0466 23.0648 29.6206 23.0837C29.1946 23.1026 28.7708 23.0118 28.39 22.82L22 19.66V23.5C22 24.163 21.7366 24.7989 21.2678 25.2678C20.7989 25.7366 20.163 26 19.5 26ZM2.5 8C2.36739 8 2.24021 8.05268 2.14645 8.14645C2.05268 8.24021 2 8.36739 2 8.5V23.5C2 23.6326 2.05268 23.7598 2.14645 23.8536C2.24021 23.9473 2.36739 24 2.5 24H19.5C19.6326 24 19.7598 23.9473 19.8536 23.8536C19.9473 23.7598 20 23.6326 20 23.5V18.05C19.9997 17.8801 20.0426 17.7128 20.1249 17.5641C20.2071 17.4154 20.3259 17.2901 20.47 17.2C20.6141 17.1099 20.7788 17.0581 20.9485 17.0493C21.1182 17.0406 21.2874 17.0752 21.44 17.15L29.28 21C29.3536 21.0403 29.4361 21.0614 29.52 21.0614C29.6039 21.0614 29.6864 21.0403 29.76 21C29.8324 20.9537 29.8922 20.8902 29.9341 20.8152C29.976 20.7402 29.9986 20.6559 30 20.57V11.43C29.9986 11.3441 29.976 11.2598 29.9341 11.1848C29.8922 11.1098 29.8324 11.0463 29.76 11C29.6864 10.9597 29.6039 10.9386 29.52 10.9386C29.4361 10.9386 29.3536 10.9597 29.28 11L21.45 14.91C21.2963 14.9875 21.1251 15.024 20.9531 15.016C20.7811 15.0079 20.6141 14.9556 20.4683 14.8641C20.3224 14.7725 20.2027 14.6449 20.1207 14.4935C20.0386 14.3421 19.9971 14.1722 20 14V8.5C20 8.36739 19.9473 8.24021 19.8536 8.14645C19.7598 8.05268 19.6326 8 19.5 8H2.5Z" fill="white"/>
        </svg>
    );
};

export default ProgrammableVideoLogo;
