import React, { useContext, useEffect } from 'react';
import { Button } from 'reactstrap';
import {
    faChevronCircleLeft,
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import Participant from './Participant';
import IndividualControls from '../Controls/IndividualControls';
import useChatFeature from '../../hooks/useChatFeature';
import useParticipants from '../../hooks/useParticipants';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant';
import { VideoContext } from '../../context/VideoContext';
import { ResponsiveContext } from '../../context/ResponsiveContext';
import Chat from '../Chat';
import SignalLogo from '../Layout/SignalLogo';
import VideoPromo from '../Layout/VideoPromo';
import ScreenShareContainer from './ScreenShareContainer';
import DeviceSelectionPopover from './DeviceSelectionPopover';
import { isMobile } from '../../utils';

const LocalParticipantContainer = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 225px;
    height: 126px;
    display: table;
    border: 1px solid #ffffff;
`;

const IndividualContainer = styled.div`
    display: flex;
`;

const VideoContainer = styled.div`
    flex: 1;
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

// WOULD RATHER NOT HAVE TO SET THE HEIGHT HERE
const MainParticipantContainer = styled.div`
    display: ${props => (props.hide ? 'none' : 'block')};
    width: 95%;
    height: ${isMobile ? '65%' : '75%'};
    margin: 0 auto;
    position: relative;
`;

const SidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #e8edf5;

    @media (max-width: 768px) {
        position: fixed;
        right: 0;
        width: 100%;
        z-index: 1;
    }
`;

const ChatHeaderContainer = styled.div`
    display: flex;
`;

const ChatHeader = styled.span`
    height: 50px;
    color: #ec3192;
    font-weight: bold;
    padding-top: 16px;
    padding-left: 12px;
`;

const Individual = ({ sidebarEnabled, setSidebarEnabled, sidebarNuked }) => {
    const { chatMessages, sendMessage } = useChatFeature();
    const { screenShareParticipant } = useScreenShareParticipant();
    const { room: { localParticipant } } = useContext(VideoContext);
    const { width } = useContext(ResponsiveContext);

    const remoteParticipant = useParticipants()[0];

    const isSidebarEnabled = width > 768 ? true : false;

    const handleToggleSidebar = () => {
        setSidebarEnabled(!sidebarEnabled);
    };

    useEffect(() => {
        if (!sidebarNuked) {
            setSidebarEnabled(isSidebarEnabled);
        }
    }, [isSidebarEnabled, setSidebarEnabled, sidebarNuked]);

    return (
        <IndividualContainer>
            <VideoContainer>
                <SignalLogo />
                <DeviceSelectionPopover
                    sidebarEnabled={sidebarEnabled}
                    sidebarNuked={sidebarNuked}
                />
                {screenShareParticipant && (
                    <ScreenShareContainer
                        participant={remoteParticipant || localParticipant}
                    />
                )}
                <MainParticipantContainer hide={!!screenShareParticipant}>
                    <Participant
                        participant={remoteParticipant || localParticipant}
                        videoPriority="high"
                        noSizeLimit
                        fullHeightVideo
                        isScreenShareParticipant={!!screenShareParticipant}
                        disableAudio={false}
                        disableVideo={!!screenShareParticipant}
                    />
                    {!!remoteParticipant && (
                        <LocalParticipantContainer>
                            <Participant
                                participant={localParticipant}
                                localIndividual={true}
                                noSizeLimit={false}
                                fullHeightVideo={false}
                                displayCameraOverScreen
                                disableAudio={false}
                                disableVideo={!!screenShareParticipant}
                            />
                        </LocalParticipantContainer>
                    )}
                </MainParticipantContainer>

                <IndividualControls
                    screenShareParticipant={screenShareParticipant}
                />
            </VideoContainer>
            {!sidebarEnabled && !sidebarNuked && (
                <Button
                    onClick={handleToggleSidebar}
                    color="link"
                    className="h-50p sidebar-button sidebar-button_fixed text-white"
                >
                    <FontAwesomeIcon icon={faChevronCircleLeft} size="2x" />
                </Button>
            )}
            {sidebarEnabled && !sidebarNuked && (
                <SidebarContainer>
                    <ChatHeaderContainer>
                        <ChatHeader>Chat</ChatHeader>
                        <Button
                            block
                            onClick={handleToggleSidebar}
                            color="link"
                            className="w-50 text-right text-decoration-none text-dark font-weight-bold sidebar-button flex-grow-1 mt-8p"
                        >
                            <FontAwesomeIcon icon={faTimesCircle} size="lg" />
                        </Button>
                    </ChatHeaderContainer>

                    <Chat
                        chatMessages={chatMessages}
                        sendMessage={sendMessage}
                    />
                </SidebarContainer>
            )}
            <VideoPromo hideMobile={true} sidebarEnabled={sidebarEnabled} sidebarNuked={sidebarNuked} />
        </IndividualContainer>
    );
};

export default Individual;
