import React, { useContext } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { VideoContext } from '../../context/VideoContext';
import { useVideoInputDevices } from '../../hooks/useDevices';
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack';
import { DEFAULT_VIDEO_CONSTRAINTS } from '../../constants';

const VideoInputList = () => {
    const videoInputDevices = useVideoInputDevices();
    const { localTracks } = useContext(VideoContext);

    const localVideoTrack = localTracks.find(track => track.kind === 'video');
    const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
    const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

    const handleVideoInputChange = ({ target: { value } }) => {
        localVideoTrack.restart({
            ...DEFAULT_VIDEO_CONSTRAINTS,
            deviceId: { exact: value }
        });
    };

    return (
        <FormGroup>
            <Label
                style={{ fontSize: '0.85rem', marginBottom: '4px' }}
            >Video Input</Label>
            <Input
                type="select"
                name="videoInput"
                value={localVideoInputDeviceId || ''}
                onChange={handleVideoInputChange}
                style={{ fontSize: '0.85rem', fontWeight: '500', padding: '.375rem .75rem .375rem .375rem' }}
            >
                {videoInputDevices.map(device => (
                    <option key={device.deviceId} value={device.deviceId}>
                        {device.label}
                    </option>
                ))}
            </Input>
        </FormGroup>
    );
};

export default VideoInputList;
