import React, { Fragment, useContext } from 'react';
import styled from 'styled-components';
import useNetworkQualityLevel from '../../hooks/useNetworkQualityLevel';
import usePublications from '../../hooks/usePublications';
import useSubscribe from '../../hooks/useSubscribe';
import useDominantSpeaker from '../../hooks/useDominantSpeaker';
import useIsTrackEnabled from '../../hooks/useIsTrackEnabled';
import useTrack from '../../hooks/useTrack';
import { VideoContext } from '../../context/VideoContext';
import Publication from './Publication';
import AudioLevelIndicator from './AudioLevelIndicator';
import NetworkQuality from './NetworkQuality';

const Indicators = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  borderRadius: '5px',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  '& *:first-child': {
    margin: '0px 5px 0px -4px',
  },
});

const Participant = ({
    participant,
    videoPriority,
    disableAudio,
    noSizeLimit,
    fullHeightVideo,
    localIndividual,
    displayCameraOverScreen,
    disableVideo,
    isScreenShareParticipant
}) => {
    const { room } = useContext(VideoContext);
    const publications = usePublications(participant);
    useSubscribe(participant);
    const dominantSpeaker = useDominantSpeaker();
    const networkQualityLevel = useNetworkQualityLevel(participant);

    let filteredPublications;

    const filterOutScreen = publications => {
        return publications.filter(pub => pub.trackName !== 'screen');
    };

    const filterOutVideoIfScreen = publications => {
        let filteredPublications;
        if (publications.some(pub => pub.trackName === 'screen')) {
            filteredPublications = publications.filter(
                p => p.trackName !== 'camera'
            );
        } else {
            filteredPublications = publications.filter(
                p => p.trackName !== 'screen'
            );
        }
        return filteredPublications;
    };

    if (displayCameraOverScreen) {
        filteredPublications = filterOutScreen(publications);
    } else {
        filteredPublications = filterOutVideoIfScreen(publications);
    }

    const isVideoEnabled = filteredPublications.filter(
        publication => publication.kind === 'video'
    );

    const audioPublication = publications.find(p => p.kind === 'audio');
    const audioTrack = useTrack(audioPublication);
    const isAudioEnabled = useIsTrackEnabled(audioTrack);
    const isDominant = participant === dominantSpeaker;
    const isLocal = participant === room.localParticipant;

    return (
        <Fragment>
            {filteredPublications.map(publication => {
                return (
                    <Publication
                        key={publication.kind}
                        publication={publication}
                        participant={participant}
                        isDominant={isDominant}
                        isLocal={isLocal}
                        videoPriority={videoPriority}
                        disableAudio={disableAudio}
                        isVideoEnabled={isVideoEnabled.length > 0}
                        isAudioEnabled={isAudioEnabled}
                        noSizeLimit={noSizeLimit}
                        fullHeightVideo={fullHeightVideo}
                        localIndividual={localIndividual}
                        disableVideo={disableVideo}
                        isScreenShareParticipant={isScreenShareParticipant}
                    />
                );
            })}
            <Indicators
              style={fullHeightVideo ? {
                top: '8px',
                right: '8px',
                padding: '3px 7px',
              } : {
                bottom: '0px',
                right: '0px',
                padding: '2px 5px',
              }}
            >
              <AudioLevelIndicator
                audioTrack={audioTrack}
                background="white"
                size={28}
              />
              <NetworkQuality
                networkQualityLevel={networkQualityLevel}
                variant="icon"
              />
            </Indicators>
        </Fragment>
    );
};

export default Participant;
