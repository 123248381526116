import React, { useEffect, useRef, useContext } from 'react';
import { VideoContext } from '../../context/VideoContext';

const AudioTrack = ({ track }) => {
    const { activeSinkId } = useContext(VideoContext);
    const audioRef = useRef(null);

    useEffect(() => {
        const el = audioRef.current;
        el.setAttribute('data-cy-audio-track-name', track.name);
        track.attach(el);
        return () => {
            track.detach(el);
        };
    }, [track]);

    useEffect(() => {
        if (audioRef.current.setSinkId) {
            audioRef.current.setSinkId(activeSinkId);
        }
    }, [activeSinkId]);

    return <audio ref={audioRef} />;
};

export default AudioTrack;
