import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import Chat from '../Chat';
import People from '../People';

const SidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #e8edf5;
    height: 100vh;

    @media (max-width: 768px) {
        position: fixed;
        right: 0;
        height: 100%;
        width: 100%;
        z-index: 1000;
    }
`;

const Sidebar = ({
    handleSidebar,
    handleToggleSidebar,
    sidebarState,
    chatMessages,
    sendMessage,
    localParticipant,
    participants
}) => {
    return (
        <SidebarContainer>
            <ButtonGroup className="w-100 pt-2 h-50p">
                <Button
                    onClick={handleSidebar('chat')}
                    color="link"
                    className="font-weight-bold text-decoration-none sidebar-button text-left pl-3"
                    style={
                        sidebarState.chat
                            ? { color: '#ec3192', width: '17%' }
                            : { color: '#6C757D', width: '17%' }
                    }
                >
                    Chat
                </Button>
                <Button
                    onClick={handleSidebar('people')}
                    color="link"
                    className="font-weight-bold text-decoration-none sidebar-button w-25 text-left"
                    style={
                        sidebarState.people
                            ? { color: '#ec3192' }
                            : { color: '#6C757D' }
                    }
                >
                    People
                </Button>
                <Button
                    onClick={handleToggleSidebar}
                    color="link"
                    className="w-50 text-right text-decoration-none text-dark font-weight-bold sidebar-button"
                >
                    <FontAwesomeIcon icon={faTimesCircle} size="lg" />
                </Button>
            </ButtonGroup>
            {sidebarState.chat && (
                <Chat chatMessages={chatMessages} sendMessage={sendMessage} />
            )}
            {sidebarState.people && (
                <People
                    localParticipant={localParticipant}
                    participants={participants}
                />
            )}
        </SidebarContainer>
    );
};

export default Sidebar;
