import React from 'react';
import { Row, Col, Jumbotron } from 'reactstrap';
import styled from 'styled-components';
import Video from 'twilio-video';
import SignalLogo from '../Layout/SignalLogo';

const LayoutContainer = styled.div`
    display: table;
    background: #F5F7FF;
    height: 100vh;
    width: 100%;
`;

const UnsupportedBrowser = ({ children }) => {
    if (!Video.isSupported) {
        return (
            <LayoutContainer>
                <Row className="m-0">
                    <Col sm="12" md={{ size: 8, offset: 2 }}>
                        <SignalLogo hexColor="#000000" />
                        <Jumbotron className="p-1 pl-5 pr-5 pb-2 pt-3 mb-3">
                            <h3 className="display-5 text-center branding-purple">
                                You are currently using an unsupported browser
                            </h3>
                            <p className="text-center">
                                Please open this application in one of the{' '}
                                <a
                                    href="https://www.twilio.com/docs/video/javascript#supported-browsers"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    supported browsers
                                </a>
                            </p>
                        </Jumbotron>
                    </Col>
                </Row>
            </LayoutContainer>
        );
    }

    return children;
};

export default UnsupportedBrowser;
