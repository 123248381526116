import { useCallback, useContext } from 'react';
import { VideoContext } from '../context/VideoContext';
import usePublications from './usePublications';

const useLocalVideoToggle = () => {
    const {
        room: { localParticipant },
        localTracks,
        getLocalVideoTrack
    } = useContext(VideoContext);
    const publications = usePublications(localParticipant);

    const videoTrack = localTracks.find(track => track.name === 'camera');
    const videoPublication = publications.find(p =>
        p.trackName.includes('camera')
    );

    const toggleVideoEnabled = useCallback(() => {
        if (videoTrack && videoPublication) {
            if (localParticipant) {
                const localTrackPublication = localParticipant.unpublishTrack(
                    videoTrack
                );
                // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
                localParticipant.emit(
                    'trackUnpublished',
                    localTrackPublication
                );
            }
            videoTrack.stop();
        } else {
            getLocalVideoTrack().then(track => {
                if (localParticipant) {
                    localParticipant.publishTrack(track);
                }
            });
        }
    }, [videoTrack, videoPublication, localParticipant, getLocalVideoTrack]);

    return [!!videoTrack && !!videoPublication, toggleVideoEnabled];
};

export default useLocalVideoToggle;
