import { Button } from 'reactstrap';
import React from 'react';
import useFullScreenToggle from '../../hooks/useFullScreenToggle';
import FullscreenIcon from '../../assets/Fullscreenicon';
import FullscreenOffIcon from '../../assets/Fullscreeniconoff';
import { isMobile } from '../../utils';

const ToggleFullScreenButton = () => {
    const [isFullScreen, toggleFullScreen] = useFullScreenToggle();

    return (
        <React.Fragment>
            {!isMobile && (
                <div className="controls-button-container ml-2">
                    <Button
                        onClick={toggleFullScreen}
                        className="controls-button"
                    >
                        {isFullScreen ? (
                            <FullscreenOffIcon />
                        ) : (
                            <FullscreenIcon />
                        )}
                    </Button>
                    Screen
                </div>
            )}
        </React.Fragment>
    );
};

export default ToggleFullScreenButton;
