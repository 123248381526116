import React from 'react';
import styled from 'styled-components';
import useScreenShareToggle from '../../hooks/useScreenShareToggle';
import ToggleScreenShareButton from './ToggleScreenShareButton';
import ToggleAudioButton from './ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton';
import LeaveMeetingButton from './LeaveMeetingButton';
import ToggleFullscreenButton from './ToggleFullScreenButton';

const ControlsContainer = styled.div`
    flex-grow: 1;
    display: flex;
    margin: 0 auto;
    align-items: center;
    padding: 10px 0;
`;

const Controls = ({ screenShareParticipant }) => {
    const [isScreenShared, toggleScreenShare] = useScreenShareToggle();
    return (
        <ControlsContainer>
            <ToggleScreenShareButton
                screenShareParticipant={screenShareParticipant}
                isScreenShared={isScreenShared}
                toggleScreenShare={toggleScreenShare}
            />
            <ToggleAudioButton />
            <ToggleVideoButton />
            <ToggleFullscreenButton />
            <LeaveMeetingButton
                isScreenShared={isScreenShared}
                toggleScreenShare={toggleScreenShare}
            />
        </ControlsContainer>
    );
};

export default Controls;
