import React from 'react';
import { useContext } from 'react';
import { Button } from 'reactstrap';
import { VideoContext } from '../../context/VideoContext';
import { isMobile } from '../../utils';
import ScreenshareIcon from '../../assets/Screenshareicon';
import ScreenshareIconOff from '../../assets/Screenshareiconoff';

const ToggleScreenShareButton = ({
    disabled,
    screenShareParticipant,
    isScreenShared,
    toggleScreenShare
}) => {
    const { room } = useContext(VideoContext);

    const disableScreenShareButton =
        screenShareParticipant &&
        screenShareParticipant !== room.localParticipant;
    const isScreenShareSupported =
        navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
    const isDisabled =
        disabled || disableScreenShareButton || !isScreenShareSupported;

    return (
        <React.Fragment>
            {!isMobile && (
                <div className="controls-button-container">
                    <Button
                        onClick={toggleScreenShare}
                        disabled={isDisabled}
                        className="controls-button"
                    >
                        {isScreenShared ? (
                            <ScreenshareIcon />
                        ) : (
                            <ScreenshareIconOff />
                        )}
                    </Button>
                    Share
                </div>
            )}
        </React.Fragment>
    );
};

export default ToggleScreenShareButton;
