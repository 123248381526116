import React from 'react';

const CameraIcon = props => {
    return (
        <svg
            className={props.className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M21.3598 6.72943L17.8598 8.60856C17.6008 8.74586 17.3849 8.94512 17.2339 9.18618C17.0829 9.42724 17.0022 9.70156 17 9.98139V14.0186C17.0025 14.2984 17.0833 14.5726 17.2342 14.8136C17.3852 15.0547 17.601 15.254 17.8598 15.3914L21.3598 17.2706C21.622 17.4165 21.9201 17.4955 22.2246 17.4998C22.5292 17.5041 22.8296 17.4335 23.0963 17.2949C23.363 17.1564 23.5866 16.9547 23.7451 16.7098C23.9036 16.465 23.9915 16.1854 24 15.8988V8.10116C23.9915 7.81455 23.9036 7.535 23.7451 7.29016C23.5866 7.04531 23.363 6.84364 23.0963 6.70509C22.8296 6.56655 22.5292 6.49591 22.2246 6.50018C21.9201 6.50445 21.622 6.58347 21.3598 6.72943Z" fill="#233659"/>
            <rect y="5.5" width="16" height="13" rx="1.5" fill="#233659"/>
        </svg>
    );
};

export default CameraIcon;