import React, { useContext, useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import axios from 'axios';
import styled from 'styled-components';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VideoContext } from '../../context/VideoContext';
import { ResponsiveContext } from '../../context/ResponsiveContext';
import useParticipants from '../../hooks/useParticipants';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant';
import useChatFeature from '../../hooks/useChatFeature';
import Participant from './Participant';
import Sidebar from '../Sidebar';
import GroupControls from '../Controls/GroupControls';
import ScreenShareContainer from './ScreenShareContainer';
import DeviceSelectionPopover from './DeviceSelectionPopover';
import bg from '../../images/bg2.png';

const GroupContainer = styled.div`
    display: flex;
    position: relative;
`;

const TitleContainer = styled.div`
    z-index: 1;
    min-height: 32px;
    margin: 22px 10px;

    & > h5 {
        color: #fff;
        max-width: 40%;
        margin: 0;
        word-break: break-all;
        font-size: 24px;
    }
`;

const LogoContainer = styled.div`
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const GroupLayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    flex: 1;
    background: #F5F7FF;
    overflow: auto;
    position: relative;
`;

const GroupVideoContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    align-content: flex-start;
    justify-content: center;
    flex-grow: 1;
    overflow: auto;

    & > div.card {
        width: 300px;
        margin: 10px 0 0 10px;
    }
`;

const Group = ({
    sidebarEnabled,
    setSidebarEnabled,
    sidebarNuked,
}) => {
    const participants = useParticipants();
    const { chatMessages, sendMessage } = useChatFeature();
    const [sidebar, setSidebar] = useState({ chat: true, people: false });
    const {
        room: { localParticipant }
    } = useContext(VideoContext);
    const { width } = useContext(ResponsiveContext);
    const { screenShareParticipant } = useScreenShareParticipant();

    const isSidebarEnabled = width > 768 ? true : false;
    const [title, setTitle] = useState('');

    useEffect(() => {
        if (!sidebarNuked) {
            setSidebarEnabled(isSidebarEnabled);
        }
    }, [isSidebarEnabled, setSidebarEnabled, sidebarNuked]);

    const handleSidebar = type => () => {
        if (type === 'chat') {
            setSidebar({ chat: true, people: false });
        } else if (type === 'people') {
            setSidebar({ chat: false, people: true });
        }
    };

    const handleToggleSidebar = () => {
        setSidebarEnabled(!sidebarEnabled);
    };

    return (
        <GroupContainer>
            <GroupLayoutContainer>
                <TitleContainer>
                    <h5>{title}</h5>
                </TitleContainer>
                <LogoContainer>
                    <img
                        src={`${process.env.PUBLIC_URL}/images/twilio.png`}
                        alt=""
                    />
                </LogoContainer>
                <DeviceSelectionPopover sidebarEnabled={sidebarEnabled} />
                {screenShareParticipant && (
                    <ScreenShareContainer
                        participant={screenShareParticipant}
                    />
                )}

                <GroupVideoContainer>
                    <Participant
                        participant={localParticipant}
                        disableVideo={!!screenShareParticipant}
                    />
                    {participants.map(participant => {
                        return (
                            <Participant
                                key={participant.sid}
                                participant={participant}
                                disableVideo={!!screenShareParticipant}
                            />
                        );
                    })}
                </GroupVideoContainer>

                <GroupControls
                    screenShareParticipant={screenShareParticipant}
                />
            </GroupLayoutContainer>
            {!sidebarEnabled && !sidebarNuked && (
                <Button
                    onClick={handleToggleSidebar}
                    color="link"
                    className="h-50p sidebar-button sidebar-button_fixed text-white"
                >
                    <FontAwesomeIcon icon={faChevronCircleLeft} size="2x" />
                </Button>
            )}
            {sidebarEnabled && !sidebarNuked && (
                <Sidebar
                    handleSidebar={handleSidebar}
                    handleToggleSidebar={handleToggleSidebar}
                    sidebarState={sidebar}
                    chatMessages={chatMessages}
                    sendMessage={sendMessage}
                    localParticipant={localParticipant}
                    participants={participants}
                />
            )}
        </GroupContainer>
    );
};

export default Group;
